import { useState } from 'react';

import { Box, Group, Text, Tooltip } from '@mantine/core';
import { IconAlertTriangleFilled } from '@tabler/icons-react';

import { getRouteApi } from '@tanstack/react-router';

import { TableOfContents } from '@/widgets/table-of-contents';

import { usePageByIdQuery, usePageSummaryQuery } from '@/entities/page';

import { formatRelativeTime } from '@/shared/lib';
import { Icon } from '@/shared/ui';

import { ContentPageLayout } from './ContentPageLayout';
import { PageSummaryToolbar } from './PageSummaryToolbar';

const route = getRouteApi('/_protected/workspaces/$workspaceSlug/pages/$pageId');

export function PageSummaryView() {
  const { pageId } = route.useParams();
  const { data: page } = usePageByIdQuery({ id: pageId });
  const { data: summary } = usePageSummaryQuery(pageId);

  const [contentElement, setContentElement] = useState<HTMLElement | null>(null);

  return (
    <>
      <PageSummaryToolbar />

      <ContentPageLayout
        title={`Summary of "${page.title}"`}
        description={page.description}
        content={summary
          ? (

              <div
                ref={setContentElement}
                dangerouslySetInnerHTML={{ __html: summary.content }}
              />
            )
          : (
              <Text c="dimmed">
                Summary for this document was not generated previously.
                Summarization of documents is processed in order, one-by-one.
                This could take some time (up to 20 minutes generally).
                This page will be automatically refreshed as soon as the summary is ready.
              </Text>
            )}
        meta={summary && (
          <Group gap={8}>
            <Text fz="sm" c="dimmed">
              {`Generated ${formatRelativeTime(summary.createdAt)}`}
            </Text>

            {summary.createdAt < page.updatedAt && (
              <Tooltip label="This summary is outdated, it will be updated soon">
                <Icon c="yellow" src={IconAlertTriangleFilled} />
              </Tooltip>
            )}
          </Group>
        )}
        aside={summary && contentElement && (
          <Box style={{ flex: 1 }}>
            <TableOfContents contentElement={contentElement} />
          </Box>
        )}
      />
    </>
  );
}
