'use client';

import { forwardRef } from 'react';

import { ActionIcon } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { useDeleteUser } from '@/features/users/delete';

import { Icon } from '@/shared/ui';

export interface DeleteUserButtonProps {
  userId: string;
  disabled?: boolean;
}

export const DeleteUserButton = forwardRef<HTMLButtonElement, DeleteUserButtonProps>(
  ({ userId, disabled = false }, ref) => {
    const openModal = useDeleteUser();

    return (
      <ActionIcon
        ref={ref}
        variant="subtle"
        color="red"
        disabled={disabled}
        onClick={() => openModal(userId)}
      >
        <Icon src={IconTrash} size={20} />
      </ActionIcon>
    );
  },
);
