import { getRouteApi } from '@tanstack/react-router';

import { ContentPageBreadcrumbs } from '@/widgets/content-page-breadcrumbs';
import { PageHeader } from '@/widgets/layouts/app';

import { usePageByIdQuery } from '@/entities/page';

const route = getRouteApi('/_protected/workspaces/$workspaceSlug/pages/$pageId');

export function PageSummaryToolbar() {
  const params = route.useParams();
  const { data: page } = usePageByIdQuery({ id: params.pageId });

  const breadcrumbs = (
    <ContentPageBreadcrumbs
      activeCurrentPage={false}
      additionalLinks={[
        {
          title: 'Summary',
          href: `/workspaces/${params.workspaceSlug}/pages/${page.id}/summary`,
          isActive: true,
        },
      ]}
    />
  );

  return (
    <PageHeader
      desktopLeftSection={breadcrumbs}
      mobileToolbar={breadcrumbs}
    />
  );
}
