import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { pagesQueryKeyFactory } from '@/entities/page';
import { workspaceQueryKeyFactory } from '@/entities/workspace';

import { api } from '@/shared/api';
import { Permission } from '@/shared/const';
import { notifySuccess } from '@/shared/lib';
import { usePermission } from '@/shared/session';
import { CONFIRMATION_MODAL_KEY } from '@/shared/ui';

interface UseDeletePageOptions {
  redirectToParent?: boolean;
}

export function useDeletePage({ redirectToParent = false }: UseDeletePageOptions) {
  const queryClient = useQueryClient();
  const canDeletePage = usePermission(Permission.DeletePages);
  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    mutationFn: (id: string) => api.pages.deletePage(id),
    async onSuccess(res) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.lists() }),
        // Invalidate parent page or workspace to update delete button state
        queryClient.invalidateQueries({
          queryKey: res.parentId
            ? pagesQueryKeyFactory.singleById(res.parentId)
            : workspaceQueryKeyFactory.single(res.workspace.slug),
        }),
      ]);

      notifySuccess('Page has been deleted');
      if (redirectToParent) {
        if (res.parentId) {
          navigate({
            ignoreBlocker: true,
            to: '/workspaces/$workspaceSlug/pages/$pageId',
            params: {
              workspaceSlug: res.workspace.slug,
              pageId: res.parentId,
            },
          });
        } else {
          navigate({
            ignoreBlocker: true,
            to: '/workspaces/$workspaceSlug',
            params: {
              workspaceSlug: res.workspace.slug,
            },
          });
        }
      }
    },
  });

  const openDeleteModal = (id: string) => openContextModal({
    modal: CONFIRMATION_MODAL_KEY,
    title: 'Delete Page',
    size: 'sm',
    innerProps: {
      text: 'Are you sure you want to delete this page?',
      confirmButtonText: 'Delete',
      onConfirm: () => mutateAsync(id),
    },
  });

  return {
    deletePage: openDeleteModal,
    canDeletePage,
  };
}
