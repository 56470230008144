'use client';

import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, NavLink, Stack } from '@mantine/core';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useParams } from '@tanstack/react-router';

import { useWorkspaceQuery } from '@/entities/workspace';

import { INDENTATION_WIDTH } from '../const';
import { DndDataProvider, useDndDataContext } from '../context/DndDataContext';
import { flatten, getProjection } from '../lib/tree';
import { NavigationItem } from './NavigationItem';
import { RootNavigationItem } from './RootNavigationItem';

import classes from './TreeNavigation.module.css';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

export function InternalTreeNavigation() {
  const { workspaceSlug } = useParams({ strict: false });
  // TODO: fix types
  const { data: workspace } = useWorkspaceQuery(workspaceSlug!);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

  const { childrenMap, moveItem } = useDndDataContext();
  const flattenedItems = flatten(childrenMap, activeId as string | null, 'root');

  const [offsetLeft, setOffsetLeft] = useState(0);
  const [, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const projected = activeId && overId
    ? getProjection(
      flattenedItems,
      activeId,
      overId,
      offsetLeft,
      INDENTATION_WIDTH,
    )
    : null;

  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems,
  ]);

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const handleDragStart = ({ active: { id: _activeId } }: DragStartEvent) => {
    setActiveId(_activeId);
    setOverId(_activeId);

    const _activeItem = flattenedItems.find(({ id }) => id === _activeId);

    if (_activeItem) {
      setCurrentPosition({
        parentId: _activeItem.parentId,
        overId: _activeId,
      });
    }
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    resetState();

    if (!projected || !over)
      return;

    const { parentId, newPreviousSibling, oldPreviousSibling } = projected;
    const activeIndex = flattenedItems.findIndex(({ id }) => id === active.id);
    const activeTreeItem = flattenedItems[activeIndex];

    const fromParentId = activeTreeItem.parentId ?? 'root';
    const toParentId = parentId ?? 'root';
    const oldPreviousSiblingId = oldPreviousSibling?.id ?? null;
    const newPreviousSiblingId = newPreviousSibling?.id ?? null;

    const isParentChanged = fromParentId !== toParentId;
    const isPositionChanged = newPreviousSiblingId !== oldPreviousSiblingId;

    if (isParentChanged || isPositionChanged) {
      moveItem(activeTreeItem, fromParentId, toParentId, newPreviousSiblingId);
    }
  };

  const handleDragCancel = () => {
    resetState();
  };

  return (
    <Stack>
      {workspace && (
        <RootNavigationItem title={workspace.title} />
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
          <Box>
            {flattenedItems.map(item => (
              <NavigationItem
                key={item.id}
                id={item.id}
                title={item.title}
                isFolder={item._count.children > 0}
                isDraft={item.isDraft}
                depth={item.id === activeId && projected ? projected.depth : item.depth}
              />
            ))}
          </Box>

          <DragOverlay className={classes.dragLayer}>
            {activeId && <NavLink opacity={0} />}
          </DragOverlay>
        </SortableContext>
      </DndContext>
    </Stack>
  );
}

export function TreeNavigation() {
  return (
    <DndDataProvider>
      <InternalTreeNavigation />
    </DndDataProvider>
  );
}
