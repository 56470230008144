import { useParams } from '@tanstack/react-router';

import { useParentPagesQuery } from '@/entities/page';
import { useWorkspaceQuery } from '@/entities/workspace';

import { BreadcrumbLink, CommonBreadcrumbs } from '@/shared/ui';

export interface ContentPageBreadcrumbsProps {
  additionalLinks?: BreadcrumbLink[];
  activeCurrentPage?: boolean;
}

export function ContentPageBreadcrumbs({
  activeCurrentPage = true,
  additionalLinks,
}: ContentPageBreadcrumbsProps) {
  // TODO: fix types
  const { workspaceSlug, pageId } = useParams({ strict: false });
  const { data: workspace } = useWorkspaceQuery(workspaceSlug!);
  const { data: parentPages = [] } = useParentPagesQuery({
    pageId: pageId!,
    enabled: !!pageId,
  });

  const links = [];

  if (workspace) {
    links.push({
      title: workspace.title,
      href: `/workspaces/${workspaceSlug}`,
      isActive: !pageId,
    });
  }

  links.push(...[
    ...parentPages.map(page => ({
      title: page.title || 'Untitled',
      href: `/workspaces/${workspaceSlug}/pages/${page.id}`,
      isActive: activeCurrentPage && page.id === pageId,
    })),
    ...additionalLinks || [],
  ]);

  return <CommonBreadcrumbs links={links} />;
}
