/* eslint-disable no-console */
import { api } from '@/shared/api';
import { env } from '@/shared/lib';

const publicVapidKey = env('VAPID_PUBLIC_KEY');

export async function isSubscribedToPushNotifications() {
  if (!canUsePushNotifications()) {
    return false;
  }

  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();

  return !!subscription;
}

export async function subscribeToPushNotifications() {
  if (!canUsePushNotifications()) {
    return;
  }

  if (Notification.permission !== 'granted') {
    console.log('Requesting permission...');
    const result = await window.Notification.requestPermission();
    console.log('Permission result', result);
    if (result !== 'granted') {
      return;
    }
  }

  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
  });

  await api.notifications.subscribe({
    subscriptionData: JSON.stringify(subscription),
  });
}

export function canUsePushNotifications() {
  return 'serviceWorker' in navigator
    && 'PushManager' in window
    && Notification.permission !== 'denied';
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
