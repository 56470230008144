import { Box, Title } from '@mantine/core';

import { TagsTable } from './TagsTable';
import { Toolbar } from './Toolbar';

export function TagsView() {
  return (
    <Box pb={40}>
      <Toolbar />

      <Title order={1} mb="xl">
        Tags
      </Title>

      <TagsTable />
    </Box>
  );
}
