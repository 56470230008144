import { Box, Card, Grid, Group, Switch, Text, Title } from '@mantine/core';

import { createFileRoute } from '@tanstack/react-router';

import { useUpdateUserSettingsMutation } from '@/features/user-settings/update';

import { userSettingsQuery, useUserSettingsQuery } from '@/entities/user-settings';

import { queryClient } from '@/shared/api';
import { RenderResponsive } from '@/shared/ui';

import { Toolbar } from './-toolbar';

import classes from './styles.module.css';

export const Route = createFileRoute('/_protected/account/settings')({
  async loader() {
    await queryClient.ensureQueryData(userSettingsQuery());
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { data: settings } = useUserSettingsQuery();
  const { mutate } = useUpdateUserSettingsMutation();

  return (
    <Box pb={40}>
      <Toolbar />

      <Title order={1} mb="xl">
        Settings
      </Title>

      <Grid>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <RenderResponsive
            base={p => <Box {...p} className={classes.notificationsSettings} />}
            md={p => <Card {...p} withBorder radius="md" p="lg" className={classes.notificationsSettings} />}
          >
            <Text fz="lg" className={classes.title} fw={500}>
              Notifications
            </Text>

            <Text fz="xs" c="dimmed" mt={3} mb={{ base: 'lg', md: 'xl' }}>
              Choose which notifications you want to receive
            </Text>

            <Group justify="space-between" className={classes.item} wrap="nowrap" gap="xl">
              <div>
                <Text>
                  Publications
                </Text>

                <Text size="xs" c="dimmed">
                  New pages published by other users
                </Text>
              </div>

              <Switch
                onLabel="ON"
                offLabel="OFF"
                size="lg"
                defaultChecked={settings['notifications.newPublications']}
                onChange={e => mutate({ 'notifications.newPublications': e.target.checked })}
              />
            </Group>
          </RenderResponsive>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
