import { ComponentProps } from 'react';

export function SearchIcon(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
      <circle cx="50.5" cy="49.5" r="31.5" fill="white" />

      <path d="M65.2065 43.797C68.6328 52.6471 64.2367 62.5984 55.3863 66.0246C46.9094 69.3062 37.4245 65.4132 33.6277 57.303C35.4428 57.8943 37.4646 57.8873 39.3841 57.1444C43.8093 55.4308 46.0079 50.4557 44.2942 46.0306C42.7813 42.1236 38.7245 39.9512 34.7491 40.679C36.6454 37.7317 39.4604 35.3395 42.9771 33.9772C51.8275 30.551 61.7792 34.947 65.2055 43.797H65.2065Z" fill="url(#paint0_linear_4_9)" />

      <path d="M29.7999 95.7218C29.6523 95.6565 29.5057 95.5892 29.3582 95.523C4.41314 84.2195 -6.81553 54.9036 4.27661 29.799C15.3677 4.69436 44.6024 -6.74668 69.7573 4.083C50.917 -3.95795 29.06 4.6271 20.7577 23.4174C18.9868 27.4259 17.9749 31.5768 17.6587 35.7097C16.4921 50.9946 24.8436 66.0385 39.5699 72.7112C39.6783 72.7614 39.7897 72.8106 39.9012 72.8597C40.0126 72.9089 40.123 72.9581 40.2335 73.0043C41.7273 73.6397 43.2492 74.1166 44.7791 74.4448C56.1192 76.8792 67.9924 71.1231 72.8634 60.0976C78.3929 47.5824 72.8282 32.9732 60.432 27.2863C75.1583 33.957 83.5098 49.0009 82.3432 64.2857C82.027 68.4197 81.0151 72.5696 79.2442 76.5781C73.017 90.6723 59.1631 99.0265 44.6757 98.9261C39.849 98.894 34.9519 97.9222 30.2446 95.9125C30.096 95.8492 29.9475 95.785 29.7999 95.7197V95.7218Z" fill="url(#paint1_linear_4_9)" />

      <path d="M70.1987 4.27899C70.3463 4.34424 70.4928 4.4115 70.6404 4.47775C95.5864 15.7803 106.814 45.0971 95.723 70.2017C84.6318 95.3064 55.3971 106.747 30.2423 95.9177C49.0826 103.959 70.9396 95.3736 79.2419 76.5833C81.0128 72.5749 82.0247 68.4239 82.3409 64.291C83.5075 49.0061 75.156 33.9622 60.4297 27.2896C60.3213 27.2394 60.2099 27.1902 60.0984 27.141C59.987 27.0918 59.8766 27.0426 59.7661 26.9964C47.2133 21.6639 32.6657 27.3879 27.1362 39.9031C21.6067 52.4183 27.1714 67.0275 39.5676 72.7144C24.8413 66.0437 16.4898 50.9998 17.6564 35.715C17.9726 31.5811 18.9845 27.4311 20.7554 23.4227C29.0567 4.63034 50.9137 -3.9537 69.754 4.08725C69.9026 4.15049 70.0511 4.21474 70.1987 4.27999V4.27899Z" fill="url(#paint2_linear_4_9)" />

      <path style={{ mixBlendMode: 'multiply' }} opacity="0.54" d="M44.6745 98.9271C44.6273 91.5055 44.6956 82.021 44.7769 74.4459C56.117 76.8803 67.9902 71.1241 72.8611 60.0987C78.3906 47.5835 72.826 32.9743 60.4298 27.2874C75.156 33.958 83.5075 49.0019 82.341 64.2868C82.0248 68.4207 81.0128 72.5707 79.2419 76.5791C73.0147 90.6733 59.1608 99.0275 44.6735 98.9271H44.6745Z" fill="url(#paint3_linear_4_9)" />

      <path style={{ mixBlendMode: 'multiply' }} opacity="0.54" d="M55.3247 1.07626C55.3719 8.49782 55.3036 17.9823 55.2223 25.5575C43.8822 23.1231 32.009 28.8793 27.1381 39.9047C21.6086 52.4199 27.1732 67.0291 39.5694 72.716C24.8432 66.0453 16.4917 51.0014 17.6582 35.7166C17.9744 31.5827 18.9864 27.4327 20.7573 23.4243C26.9835 9.33003 40.8374 0.975871 55.3247 1.07626Z" fill="url(#paint4_linear_4_9)" />

      <defs>
        <linearGradient id="paint0_linear_4_9" x1="33.197" y1="56.1884" x2="65.2056" y2="43.7918" gradientUnits="userSpaceOnUse">
          <stop stopColor="#68A0EE" />

          <stop offset="1" stopColor="#1659A7" />
        </linearGradient>

        <linearGradient id="paint1_linear_4_9" x1="64.4686" y1="1.7317" x2="24.0631" y2="93.174" gradientUnits="userSpaceOnUse">
          <stop stopColor="#468AEF" />

          <stop offset="1" stopColor="#478BE7" />
        </linearGradient>

        <linearGradient id="paint2_linear_4_9" x1="35.5252" y1="98.24" x2="75.9303" y2="6.79868" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4989E9" />

          <stop offset="1" stopColor="#73ABF7" />
        </linearGradient>

        <linearGradient id="paint3_linear_4_9" x1="77.778" y1="48.4855" x2="52.5309" y2="68.2612" gradientUnits="userSpaceOnUse">
          <stop stopColor="#161D6F" />

          <stop offset="0.12" stopColor="#1A2171" />

          <stop offset="0.25" stopColor="#282F7A" />

          <stop offset="0.39" stopColor="#3F4587" />

          <stop offset="0.54" stopColor="#5F649B" />

          <stop offset="0.7" stopColor="#898CB3" />

          <stop offset="0.85" stopColor="#BBBCD1" />

          <stop offset="1" stopColor="#F2F2F2" />
        </linearGradient>

        <linearGradient id="paint4_linear_4_9" x1="22.2162" y1="51.4874" x2="47.4633" y2="31.7117" gradientUnits="userSpaceOnUse">
          <stop stopColor="#161D6F" />

          <stop offset="0.12" stopColor="#1A2171" />

          <stop offset="0.25" stopColor="#282F7A" />

          <stop offset="0.39" stopColor="#3F4587" />

          <stop offset="0.54" stopColor="#5F649B" />

          <stop offset="0.7" stopColor="#898CB3" />

          <stop offset="0.85" stopColor="#BBBCD1" />

          <stop offset="1" stopColor="#F2F2F2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
