import { api } from '@/shared/api';
import { defineQuery } from '@/shared/lib';

export const attachmentQueryKeyFactory = {
  all: () => ['attachments'] as const,
  single: (id: string) => [...attachmentQueryKeyFactory.all(), 'single', id] as const,
};

export const [usePageAttachmentQuery] = defineQuery((id: string) => ({
  queryKey: attachmentQueryKeyFactory.single(id),
  queryFn: () => api.pageAttachments.get(id),
}));
