import { useInView } from 'react-intersection-observer';

import { Center, Loader, Paper, Table } from '@mantine/core';

import { ColumnDef, flexRender, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';

import classes from './DataTable.module.css';

export interface DataTableProps<T extends RowData, V> {
  data: T[];
  columns: ColumnDef<T, V>[];
  hasNextPage?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
  onRowClick?: (item: T) => void;
}

export function DataTable<T extends RowData, V>({
  data,
  columns,
  hasNextPage,
  isLoading = false,
  onLoadMore,
  onRowClick,
}: DataTableProps<T, V>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { ref } = useInView({
    threshold: 1,
    onChange(inView) {
      if (inView && hasNextPage) {
        onLoadMore?.();
      }
    },
  });

  return (
    <Paper shadow="sm" className={classes.tableWrapper}>
      <Table
        highlightOnHover
        verticalSpacing="md"
        horizontalSpacing="md"
        classNames={{
          th: classes.th,
          tr: onRowClick && classes.clickableRow,
        }}
      >
        <Table.Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Table.Th
                  key={header.id}
                  style={{
                    minWidth: header.column.columnDef.minSize,
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                </Table.Th>
              ))}
            </Table.Tr>
          ))}
        </Table.Thead>

        <Table.Tbody>
          {table.getRowModel().rows.map(row => (
            <Table.Tr key={row.id} onClick={() => onRowClick?.(row.original)}>
              {row.getVisibleCells().map(cell => (
                <Table.Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      {isLoading
        ? (
            <Center py={16}>
              <Loader size="lg" type="dots" />
            </Center>
          )
        : (
            <div ref={ref} />
          )}
    </Paper>
  );
}
