'use client';

import { createColumnHelper } from '@tanstack/react-table';

import { groupsQuery, useGroupsQuery } from '@/entities/group';

import { formatRelativeTime, QueryResult } from '@/shared/lib';
import { DataTable } from '@/shared/ui';

import { ActionsCell } from '@/views/admin-groups/ui/ActionsCell';

const colDef = createColumnHelper<QueryResult<typeof groupsQuery>[number]>();
const columns = [
  colDef.display({
    id: 'name',
    header: 'Name',
    minSize: 180,
    cell: ({ row }) => row.original.name,
  }),
  colDef.display({
    id: 'users',
    header: 'Users',
    minSize: 80,
    cell: ({ row }) => row.original._count?.users || '—',
  }),
  colDef.display({
    id: 'createdAt',
    header: 'Created',
    minSize: 120,
    cell: ({ row }) => formatRelativeTime(row.original.createdAt),
  }),
  colDef.display({
    id: 'actions',
    size: 1,
    cell: ActionsCell,
  }),
];

export function GroupsTable() {
  const { data = [] } = useGroupsQuery({
    countUsers: true,
  });

  return (
    <DataTable data={data} columns={columns} />
  );
}
