import { Link } from '@mantine/tiptap';

import { Highlight } from '@tiptap/extension-highlight';
import { Subscript } from '@tiptap/extension-subscript';
import { Superscript } from '@tiptap/extension-superscript';
import { TableRow } from '@tiptap/extension-table-row';
import { Underline } from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import { HeadingId } from '../extensions/heading-ids';
import { Pdf } from '../extensions/pdf';
import { CustomTable } from '../extensions/table';
import { CustomTableCell } from '../extensions/table-cell';
import { CustomTableHeader } from '../extensions/table-header';

interface UseRichContentEditorOptions {
  content: string;
  editable?: boolean;
  onChange?: (value: string) => void;
}

export function useRichContentEditor({ editable = true, content, onChange }: UseRichContentEditorOptions, deps?: readonly unknown[]) {
  return useEditor({
    content,
    editable,
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [2, 3, 4],
        },
      }),
      Underline,
      Link,
      Superscript,
      Subscript,
      Highlight,
      HeadingId,
      CustomTable.configure({
        resizable: true,
      }),
      CustomTableCell,
      CustomTableHeader,
      TableRow,
      Pdf,
    ],
    onUpdate({ editor }) {
      onChange?.(editor.getHTML());
    },
  }, deps);
}
