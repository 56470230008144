import { useMutation, useQueryClient } from '@tanstack/react-query';

import { pagesQueryKeyFactory } from '@/entities/page';

import { api, Pages } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

export function usePublishPageMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Pages.PublishPage.RequestParams) => api.pages.publishPage(data.id),
    async onSuccess(res) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.lists() }),
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.singleById(res.id) }),
      ]);

      notifySuccess('Page has been published');
    },
  });
}
