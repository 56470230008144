import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tagQueryKeyFactory } from '@/entities/tag';

import { api } from '@/shared/api';
import { Permission } from '@/shared/const';
import { notifySuccess } from '@/shared/lib';
import { usePermission } from '@/shared/session';
import { CONFIRMATION_MODAL_KEY } from '@/shared/ui';

export function useDeleteTag() {
  const queryClient = useQueryClient();
  const canDeleteTag = usePermission(Permission.ManageTags);

  const { mutateAsync } = useMutation({
    mutationFn: (id: string) => api.tags.deleteTag(id),
    async onSuccess({ name }) {
      await queryClient.invalidateQueries({ queryKey: tagQueryKeyFactory.list() });

      notifySuccess(`Tag "${name}" has been deleted`);
    },
  });

  const openDeleteModal = (id: string) => openContextModal({
    modal: CONFIRMATION_MODAL_KEY,
    title: 'Delete Tag',
    size: 'sm',
    innerProps: {
      text: 'Are you sure you want to delete this tag?',
      confirmButtonText: 'Delete',
      onConfirm: () => mutateAsync(id),
    },
  });

  return {
    deleteTag: openDeleteModal,
    canDeleteTag,
  };
}
