import { useEffect } from 'react';

import { Notifications } from '@mantine/notifications';

import NiceModal from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { createRootRoute, ErrorComponent as BaseErrorComponent, ErrorComponentProps, Outlet } from '@tanstack/react-router';

import { ModalsProvider } from '@/app/modals';

import { ProgressBar } from '@/widgets/progress-bar';

export const Route = createRootRoute({
  component: Component,
  errorComponent: ErrorComponent,
});

function Component() {
  return (
    <ProgressBar>
      <Notifications zIndex={10000} />

      <NiceModal.Provider>
        <ModalsProvider>
          <Outlet />
        </ModalsProvider>
      </NiceModal.Provider>
    </ProgressBar>
  );
}

function ErrorComponent(props: ErrorComponentProps) {
  const { error } = props;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <BaseErrorComponent {...props} />
  );
}
