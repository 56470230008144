import { ComponentProps } from 'react';

import { Textarea } from '@mantine/core';

import clsx from 'clsx';

import classes from './style.module.css';

export function TitleInput(props: ComponentProps<typeof Textarea>) {
  return (
    <Textarea
      placeholder="Title"
      autosize
      minRows={1}
      rows={1}
      classNames={{
        input: clsx(classes.title, classes.unstyledInput),
      }}
      {...props}
    />
  );
}
