import { useDebouncedValue } from '@mantine/hooks';

import { useQuery } from '@tanstack/react-query';

import { api } from '@/shared/api';

export interface UseSearchQueryOptions {
  query: string;
  workspaceSlug?: string;
}

const MIN_QUERY_LENGTH = 3;

export function useSearchQuery({ query, workspaceSlug }: UseSearchQueryOptions) {
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const preparedQuery = debouncedQuery.trim();
  const isEnabled = preparedQuery.length >= MIN_QUERY_LENGTH;

  return useQuery({
    queryKey: ['search', workspaceSlug, debouncedQuery],
    queryFn: () => api.search.searchPages({
      query: preparedQuery,
      workspaceSlug,
    }),
    enabled: isEnabled,
    placeholderData: prevData => (isEnabled ? prevData : undefined),
  });
}
