import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';

import { useMovePageMutation } from '@/features/pages/move';

import { childrenPagesQuery } from '@/entities/page';

import { NavigationItem } from '../types';

interface Context {
  childrenMap: Record<string, NavigationItem[]>;
  loadingMap: Record<string, boolean>;
  openedItems: Record<string, boolean>;
  setItemOpenState: (id: string, isOpened: boolean) => void;
  moveItem: (item: NavigationItem, fromParentId: string, toParentId: string, previousItemId: string | null) => void;
}

const DndDataContext = createContext<Context>({
  childrenMap: {},
  loadingMap: {},
  openedItems: {},
  setItemOpenState: () => {},
  moveItem: () => Promise.resolve(),
});

export function DndDataProvider({ children }: PropsWithChildren) {
  const [openedItems, setOpenedItems] = useState<Context['openedItems']>({});
  const openedIds = useMemo(
    () => Object.entries(openedItems)
      .filter(([, isOpened]) => isOpened)
      .map(([id]) => id),
    [openedItems],
  );

  // TODO: fix types
  const { workspaceSlug } = useParams({ strict: false });
  const { childrenMap, loadingMap, itemsMap } = useQueries({
    queries: [
      childrenPagesQuery({ workspaceSlug: workspaceSlug!, parentId: null }),
      ...openedIds.map(parentId => childrenPagesQuery({
        workspaceSlug: workspaceSlug!,
        parentId,
      })),
    ],
    combine(queries) {
      return queries.reduce((acc, query, index) => {
        const id = index === 0 ? 'root' : openedIds[index - 1];

        acc.childrenMap[id] = query.data ?? [];
        acc.loadingMap[id] = query.isLoading;
        query.data?.forEach((item) => {
          acc.itemsMap[item.id] = item;
        });

        return acc;
      }, {
        childrenMap: {} as Context['childrenMap'],
        loadingMap: {} as Context['loadingMap'],
        itemsMap: {} as Record<string, NavigationItem>,
      });
    },
  });

  const { mutate: movePage } = useMovePageMutation();
  const setItemOpenState: Context['setItemOpenState'] = (id, isOpened) => {
    setOpenedItems(current => ({ ...current, [id]: isOpened }));
  };

  const ctx = useMemo<Context>(() => ({
    childrenMap,
    loadingMap,
    openedItems,

    setItemOpenState,
    moveItem(item, fromParentId, toParentId, previousItemId) {
      movePage({
        item,
        fromParent: itemsMap[fromParentId] ?? null,
        toParent: itemsMap[toParentId] ?? null,
        previousSiblingId: previousItemId ?? null,
      });
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [openedItems, childrenMap, loadingMap, itemsMap]);

  return (
    <DndDataContext.Provider value={ctx}>
      {children}
    </DndDataContext.Provider>
  );
}

export const useDndDataContext = () => useContext(DndDataContext);
