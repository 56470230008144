import { useMutation } from '@tanstack/react-query';

import { downloadFile } from '@/shared/lib';

// I could have used just <a download="..." href="..."> but I wanted to show a loader and error message
export function useExportPageAsPdf() {
  return useMutation({
    async mutationFn(id: string) {
      const response = await fetch(`/api/pdf/page/${id}`);
      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }

      const name = response.headers.get('File-Name') as string;
      const file = await response.blob();

      downloadFile(name, file);
    },
  });
}
