export interface Heading {
  depth: number;
  content: string;
  id: string;
  getNode: () => HTMLHeadingElement;
}

function getHeadingsData(headings: HTMLHeadingElement[]): Heading[] {
  const result: Heading[] = [];

  for (let i = 0; i < headings.length; i += 1) {
    const heading = headings[i];
    if (heading.id) {
      result.push({
        depth: Number.parseInt(heading.tagName.replace('H', ''), 10),
        content: heading.textContent || '',
        id: heading.id,
        getNode: () => document.getElementById(heading.id) as HTMLHeadingElement,
      });
    }
  }

  return result;
}

export function getHeadings(content: HTMLElement): Heading[] {
  return getHeadingsData(Array.from(content.querySelectorAll('h1, h2, h3, h4, h5, h6')));
}
