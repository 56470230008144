import { useMutation, useQueryClient } from '@tanstack/react-query';

import { pagesQueryKeyFactory } from '@/entities/page';
import { workspaceQueryKeyFactory } from '@/entities/workspace';

import { api, Pages } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

export function useCreatePageMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: Pages.CreatePage.RequestBody) => api.pages.createPage(vars),
    async onSuccess(res) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.lists() }),
        // Invalidate parent page or workspace to update delete button state
        queryClient.invalidateQueries({
          queryKey: res.parentId
            ? pagesQueryKeyFactory.singleById(res.parentId)
            : workspaceQueryKeyFactory.single(res.workspace.slug),
        }),
      ]);

      notifySuccess('Page draft has been created');
    },
  });
}
