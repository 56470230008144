import { createFileRoute, Outlet } from '@tanstack/react-router';

import { NothingFound } from '@/views/not-found';

import { pageByIdQuery, parentPagesQuery } from '@/entities/page';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug/pages/$pageId')({
  async loader({ params }) {
    await Promise.all([
      await queryClient.ensureQueryData(pageByIdQuery({ id: params.pageId })),
      await queryClient.ensureQueryData(parentPagesQuery({ pageId: params.pageId })),
    ]);
  },
  component: Component,
  notFoundComponent: NotFoundComponent,
});

function Component() {
  const params = Route.useParams();
  return <Outlet key={params.pageId} />;
}

function NotFoundComponent() {
  const { workspaceSlug } = Route.useParams();

  return (
    <NothingFound
      buttonText="Go to workspace"
      buttonLink={`/workspaces/${workspaceSlug}`}
    />
  );
}
