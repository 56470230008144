import { PropsWithChildren } from 'react';

import { Box, Center, Title } from '@mantine/core';

import { APP_SHORT_NAME } from '@/shared/const';

export function BlankLayout({ children }: PropsWithChildren) {
  return (
    <Center w="100%" mih="90%" p="md">
      <Box w="100%" maw={400}>
        <Title ta="center" order={1} mb="lg">
          {APP_SHORT_NAME}
        </Title>

        {children}
      </Box>
    </Center>
  );
}
