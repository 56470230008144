import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { workspaceQueryKeyFactory } from '@/entities/workspace';

import { api } from '@/shared/api';
import { Permission } from '@/shared/const';
import { notifySuccess } from '@/shared/lib';
import { usePermission } from '@/shared/session';
import { CONFIRMATION_MODAL_KEY } from '@/shared/ui';

interface UseDeleteWorkspaceOptions {
  onSuccess?: () => void;
}

export function useDeleteWorkspace({ onSuccess }: UseDeleteWorkspaceOptions) {
  const queryClient = useQueryClient();
  const canDeleteWorkspace = usePermission(Permission.DeleteWorkspaces);

  const { mutateAsync } = useMutation({
    mutationFn: (id: string) => api.workspaces.deleteWorkspace(id),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: workspaceQueryKeyFactory.list() });

      notifySuccess('Workspace has been deleted');
      onSuccess?.();
    },
  });

  const openDeleteModal = (id: string) => openContextModal({
    modal: CONFIRMATION_MODAL_KEY,
    title: 'Delete Workspace',
    size: 'sm',
    innerProps: {
      text: 'Are you sure you want to delete this workspace?',
      confirmButtonText: 'Delete',
      onConfirm: () => mutateAsync(id),
    },
  });

  return {
    deleteWorkspace: openDeleteModal,
    canDeleteWorkspace,
  };
}
