import { useEffect } from 'react';

import { useDndDataContext } from '../context/DndDataContext';

export function useItemOpenState(id: string, defaultState = false) {
  const { openedItems, setItemOpenState } = useDndDataContext();

  useEffect(() => {
    if (defaultState) {
      setItemOpenState(id, defaultState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [openedItems[id] || false, {
    toggle: () => setItemOpenState(id, !openedItems[id]),
    open: () => setItemOpenState(id, true),
  }] as const;
}
