import { createFileRoute, Outlet } from '@tanstack/react-router';

import { WorkspaceNavigationView } from '@/views/workspace-navigation';

import { AppLayout } from '@/widgets/layouts/app';

import { childrenPagesQuery } from '@/entities/page';
import { workspaceQuery } from '@/entities/workspace';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug')({
  async loader({ params }) {
    await Promise.all([
      queryClient.ensureQueryData(workspaceQuery(params.workspaceSlug)),
      queryClient.ensureQueryData(childrenPagesQuery({
        workspaceSlug: params.workspaceSlug,
        parentId: null,
      })),
    ]);
  },
  component: Component,
});

function Component() {
  return (
    <AppLayout sidebar={<WorkspaceNavigationView />}>
      <Outlet />
    </AppLayout>
  );
}
