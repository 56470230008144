import { PageHeader } from '@/widgets/layouts/app';

import { CommonBreadcrumbs } from '@/shared/ui';

export function Toolbar() {
  const breadcrumbs = <CommonBreadcrumbs links={[{ title: 'Settings', href: '/account/settings', isActive: true }]} />;

  return (
    <PageHeader
      desktopLeftSection={breadcrumbs}
      mobileToolbar={breadcrumbs}
    />
  );
}
