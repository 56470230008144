// eslint-disable-next-line ts/ban-ts-comment
// @ts-nocheck
import { Table } from '@tiptap/extension-table';
import { mergeAttributes } from '@tiptap/react';

export const CustomTable = Table.extend({
  addAttributes() {
    return {
      style: {
        default: null,
      },
    };
  },
  renderHTML({ node, HTMLAttributes }) {
    const attrs = HTMLAttributes;
    let totalWidth = 0;
    let fixedWidth = true;

    try {
      // use first row to determine width of table;
      const tr = node.content.content[0];
      tr.content.content.forEach((td) => {
        if (td.attrs.colwidth) {
          td.attrs.colwidth.forEach((col) => {
            if (!col) {
              fixedWidth = false;
              totalWidth += this.options.cellMinWidth;
            } else {
              totalWidth += col;
            }
          });
        } else {
          fixedWidth = false;
          const colspan = td.attrs.colspan ? td.attrs.colspan : 1;
          totalWidth += this.options.cellMinWidth * colspan;
        }
      });
    } catch (error) {
      fixedWidth = false;
    }

    if (fixedWidth && totalWidth > 0) {
      attrs.style = `width: ${totalWidth}px;`;
    } else if (totalWidth && totalWidth > 0) {
      attrs.style = `min-width: ${totalWidth}px`;
    } else {
      attrs.style = null;
    }

    return ['div', { class: 'table-wrapper' }, ['table', mergeAttributes(this.options.HTMLAttributes, attrs), ['tbody', 0]]];
  },
});
