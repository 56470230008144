import { PropsWithChildren, ReactNode } from 'react';

import { ActionIcon, AppShellHeader, Box, Burger, Container, Flex, Group, Indicator, Menu } from '@mantine/core';
import { IconBell, IconDots } from '@tabler/icons-react';

import { Link } from '@tanstack/react-router';

import { useNotificationsList } from '@/widgets/notifications-list';

import { useUnreadNotificationsCount } from '@/entities/notifications';

import { Icon } from '@/shared/ui';

import { useAppLayout } from '../AppLayout.context';
import { Logo } from '../Logo';
import { MobileToolbar } from './MobileToolbar';

import classes from './PageHeader.module.css';

export interface PageHeaderProps extends PropsWithChildren {
  desktopLeftSection?: ReactNode;
  desktopActions?: ReactNode;
  mobileActions?: ReactNode;
  mobileMenu?: ReactNode;
  mobileToolbar?: ReactNode;
}

export function PageHeader({
  desktopLeftSection,
  desktopActions,
  mobileActions,
  mobileMenu,
  mobileToolbar,
}: PageHeaderProps) {
  const ctx = useAppLayout();

  const openNotificationsList = useNotificationsList();
  const { data: unreadNotificationsCount = 0 } = useUnreadNotificationsCount();

  return (
    <>
      <AppShellHeader withBorder={false}>
        <Container h="100%" fluid px={16}>
          <Group h="100%" gap="md" align="center" wrap="nowrap">
            <Group className="no-print" hiddenFrom="md" align="center" gap={2} flex={1}>
              <Burger
                opened={ctx.isSidebarOpened}
                onClick={ctx.toggleSidebar}
                size="sm"
              />

              <Indicator disabled={unreadNotificationsCount === 0} size={8} offset={6} color="red" processing>
                <ActionIcon size="lg" variant="transparent" color="default" onClick={openNotificationsList}>
                  <Icon src={IconBell} size={24} />
                </ActionIcon>
              </Indicator>
            </Group>

            <Box hiddenFrom="md" component={Link} display="block" href="/">
              <Box component={Logo} h={24} pb={4} display="block" />
            </Box>

            <Box className={classes.headerContent}>
              <Group wrap="nowrap" justify="space-between" align="center">
                <Box miw={0}>
                  <Box visibleFrom="md" display="contents">
                    {desktopLeftSection}
                  </Box>
                </Box>

                <Group gap={2} wrap="nowrap">
                  {desktopActions && (
                    <Box visibleFrom="md" display="contents">
                      {desktopActions}
                    </Box>
                  )}

                  {mobileActions && (
                    <Box hiddenFrom="md" display="contents">
                      {mobileActions}
                    </Box>
                  )}

                  {mobileMenu && (
                    <Flex hiddenFrom="md" justify="end" style={{ flex: '1 0 auto' }}>
                      <Menu
                        position="top-end"
                        transitionProps={{
                          duration: 200,
                          transition: 'pop-top-right',
                        }}
                      >
                        <Menu.Target>
                          <ActionIcon size="lg" variant="transparent" color="default">
                            <Icon src={IconDots} size={24} />
                          </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                          {mobileMenu}
                        </Menu.Dropdown>
                      </Menu>
                    </Flex>
                  )}
                </Group>
              </Group>
            </Box>
          </Group>
        </Container>
      </AppShellHeader>

      {mobileToolbar && (
        <MobileToolbar>
          {mobileToolbar}
        </MobileToolbar>
      )}
    </>
  );
}
