import { useEffect } from 'react';

import { canUsePushNotifications, isSubscribedToPushNotifications } from './lib';
import { usePushNotificationsPermissionRequestModal } from './PushNotificationsPermissionRequestModal';

export function usePushNotifications() {
  const showModal = usePushNotificationsPermissionRequestModal();
  const checkAndShow = async () => {
    const isSubscribed = await isSubscribedToPushNotifications();
    if (canUsePushNotifications() && !isSubscribed) {
      showModal();
    }
  };

  useEffect(() => {
    checkAndShow();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
