'use client';

import { useMemo } from 'react';

import { Badge, Box, Group, Select, Tooltip } from '@mantine/core';
import { useSetState } from '@mantine/hooks';

import { createColumnHelper } from '@tanstack/react-table';

import { useGroupsQuery } from '@/entities/group';
import { useUsersQuery } from '@/entities/user';

import { useWritableSearchParams } from '@/shared/hooks';
import { formatRelativeTime } from '@/shared/lib';
import { QueryResult } from '@/shared/types';
import { DataTable } from '@/shared/ui';

import { DeleteUserButton } from './DeleteUserButton';
import { EditUserButton } from './EditUserButton';
import { SendInvitationButton } from './SendInvitationButton';
import { UserStatusButton } from './UserStatusButton';

const colDef = createColumnHelper<QueryResult<typeof useUsersQuery>[number]>();
const columns = [
  colDef.display({
    id: 'name',
    header: 'Name',
    minSize: 180,
    cell: ({ row }) => row.original.name,
  }),
  colDef.display({
    id: 'email',
    header: 'Email',
    minSize: 180,
    cell: ({ row }) => row.original.email,
  }),
  colDef.display({
    id: 'groups',
    header: 'Groups',
    minSize: 180,
    cell: ({ row }) => (
      <Group gap={4}>
        {row.original.groups.map(group => (
          <Badge key={group.id} size="sm" variant="light">
            {group.name}
          </Badge>
        ))}
      </Group>
    ),
  }),
  colDef.display({
    id: 'createdAt',
    header: 'Created',
    minSize: 120,
    cell: ({ row }) => formatRelativeTime(row.original.createdAt),
  }),
  colDef.display({
    id: 'actions',
    size: 1,
    cell: (ctx) => {
      const { id, isActive, availableActions, meta } = ctx.row.original;

      return (
        <Group gap={4} wrap="nowrap" justify="end">
          {availableActions?.canChangeStatus && <UserStatusButton userId={id} isActive={isActive} />}

          {availableActions?.canSendInvitation && <SendInvitationButton userId={id} />}

          <EditUserButton userId={id} />

          {meta && !meta.isSystemOrCurrent && (
            <Tooltip
              label="User already has some activity within the system and can't be deleted. Use deactivation instead."
              multiline
              maw={300}
              ta="center"
              disabled={availableActions?.canDelete}
            >
              <DeleteUserButton userId={id} disabled={!availableActions?.canDelete} />
            </Tooltip>
          )}
        </Group>
      );
    },
  }),
];

export function UsersTable() {
  // TODO: replace this with useQueryState
  const searchParams = useWritableSearchParams();
  const [filter, setFilter] = useSetState({
    group: searchParams.get('group'),
  });

  const { data: users = [] } = useUsersQuery({
    group: filter.group || undefined,
    keepPreviousResult: true,
  });

  const { data: groups = [] } = useGroupsQuery();
  const groupOptions = useMemo(() => groups.map(group => ({
    value: group.id,
    label: group.name,
  })), [groups]);

  const onGroupChange = (value: string | null) => {
    setFilter({ group: value });
    if (value) {
      searchParams.set('group', value);
    } else {
      searchParams.delete('group');
    }
  };

  return (
    <Box>
      <Group gap="xs" mb="sm">
        <Select
          placeholder="Filter by group"
          data={groupOptions}
          allowDeselect
          clearable
          checkIconPosition="right"
          value={filter.group}
          onChange={onGroupChange}
        />
      </Group>

      <DataTable data={users} columns={columns} />
    </Box>
  );
}
