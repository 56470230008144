import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { usePushNotifications } from '@/features/push-notifications';

import { queryClient } from '@/shared/api';
import { sessionQuery } from '@/shared/session';

export const Route = createFileRoute('/_protected')({
  async beforeLoad() {
    const user = await queryClient.ensureQueryData(sessionQuery);

    if (!user) {
      throw redirect({
        to: '/auth/signin',
        replace: true,
      });
    }
  },
  component: Component,
});

function Component() {
  usePushNotifications();

  return <Outlet />;
}
