import { ActionIcon, Affix, Loader, NavLink, Stack, Tooltip, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import { useNavigate, useParams } from '@tanstack/react-router';

import { AdminLink } from '@/widgets/admin-link';
import { searchHandlers, SearchIcon, SearchSpotlight } from '@/widgets/search';

import { useCreatePageMutation } from '@/features/pages/create';

import { Permission } from '@/shared/const';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

import { TreeNavigation } from './TreeNavigation';

export function WorkspaceNavigationView() {
  const canCreatePage = usePermission(Permission.WritePages);
  // TODO: fix types
  const { workspaceSlug } = useParams({ strict: false });
  const navigate = useNavigate();

  const createPageMutation = useCreatePageMutation();
  const createPage = async () => {
    const page = await createPageMutation.mutateAsync({
      workspaceSlug: workspaceSlug!,
      parentId: null,
    });

    navigate({
      to: '/workspaces/$workspaceSlug/pages/$pageId/edit',
      params: { workspaceSlug: workspaceSlug!, pageId: page.id },
    });
  };

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <>
      <Stack gap={0}>
        <AdminLink />

        {canCreatePage && (
          <NavLink
            variant="sidebar"
            label="Create page"
            leftSection={<Icon size={20} src={IconPlus} />}
            rightSection={createPageMutation.isPending && <Loader size={16} />}
            disabled={createPageMutation.isPending}
            onClick={createPage}
          />
        )}

        <TreeNavigation />
      </Stack>

      <SearchSpotlight workspaceSlug={workspaceSlug!} />

      <Affix display="flex" bottom={isMobile ? 48 : 16} right={16}>
        <Tooltip label="Search">
          <ActionIcon size={60} variant="transparent" onClick={searchHandlers.open} radius="xl">
            <SearchIcon width={60} height={60} />
          </ActionIcon>
        </Tooltip>
      </Affix>
    </>
  );
}
