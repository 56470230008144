import { useEffect, useRef, useState } from 'react';

import { Box, Group, Text } from '@mantine/core';
import { useMutationObserver } from '@mantine/hooks';
import { IconListSearch } from '@tabler/icons-react';

import { useLocation, useNavigate } from '@tanstack/react-router';
import clsx from 'clsx';

import { Icon } from '@/shared/ui';

import { getActiveElement, getHeadings, Heading } from '../lib';

import classes from './TableOfContents.module.css';

export interface TableOfContentsProps {
  contentElement: HTMLElement;
}

export function TableOfContents({ contentElement }: TableOfContentsProps) {
  const [active, setActive] = useState(0);
  const [headings, setHeadings] = useState<Heading[]>([]);
  const headingsRef = useRef<Heading[]>([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const filteredHeadings = headings.filter(heading => heading.depth > 1);

  function handleScroll() {
    const rects = headingsRef.current.map(d => d.getNode().getBoundingClientRect());
    setActive(getActiveElement(rects));
  }

  const updateHeadings = () => {
    const tempHeadings = getHeadings(contentElement);
    headingsRef.current = tempHeadings;
    setHeadings(tempHeadings);
  };

  const updateToc = () => {
    updateHeadings();
    handleScroll();
  };

  useMutationObserver(updateToc, {
    subtree: true,
    childList: true,
  }, contentElement);

  useEffect(() => {
    updateToc();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentElement]);

  return (
    <div>
      {filteredHeadings.length > 0 && (
        <>
          <Group mb="md">
            <Icon src={IconListSearch} size={18} />

            <Text>Table of contents</Text>
          </Group>

          <div>
            {filteredHeadings.map((h, idx) => {
              const headingHash = `#${h.id}`;

              return (
                <Box
                  component="a"
                  href={headingHash}
                  key={h.id}
                  className={clsx(classes.link, { [classes.linkActive]: active === idx })}
                  style={{ paddingLeft: `calc(${h.depth - 1} * var(--mantine-spacing-md))` }}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate({ to: `${pathname}${headingHash}` });
                  }}
                >
                  {h.content}
                </Box>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
