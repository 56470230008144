import { useEffect } from 'react';

import { ActionIcon, Center, Drawer, Group, Loader, Menu, Skeleton, Stack, Text } from '@mantine/core';
import { IconDots, IconEye } from '@tabler/icons-react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useLocation } from '@tanstack/react-router';

import { useMarkAllNotificationsAsReadMutation } from '@/features/notifications/mark-as-read';

import { NotificationCard, NOTIFICATIONS_PAGE_SIZE, useNotificationQuery } from '@/entities/notifications';

import { useInfiniteScrollRef, useIsMobile } from '@/shared/hooks';
import { toDate } from '@/shared/lib';
import { Icon } from '@/shared/ui';

export const NotificationsList = NiceModal.create(() => {
  const modal = useModal();
  const location = useLocation();

  useEffect(() => {
    modal.hide();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const markAllAsReadMutation = useMarkAllNotificationsAsReadMutation();
  const { data = [], isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useNotificationQuery();
  const lastItemElementRef = useInfiniteScrollRef({
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  });

  const isMobile = useIsMobile();

  return (
    <Drawer.Root
      keepMounted
      opened={modal.visible}
      onClose={modal.hide}
      size={isMobile ? '100%' : undefined}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header>
          <Group w="100%" justify="space-between" align="center">
            <Drawer.Title>Notifications</Drawer.Title>
            <Group gap="xs">
              <Menu>
                <Menu.Target>
                  <ActionIcon variant="subtle" color="dark">
                    <Icon src={IconDots} size={20} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={markAllAsReadMutation.isPending ? <Loader size={20} /> : <Icon src={IconEye} size={20} />}
                    onClick={() => markAllAsReadMutation.mutate()}
                  >
                    Mark all as read
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Drawer.CloseButton />
            </Group>
          </Group>
        </Drawer.Header>

        <Drawer.Body>
          <Stack pt="sm">
            {data.length === 0 && !isLoading && (
              <Text c="dimmed" ta="center">
                There are no notifications for you
              </Text>
            )}

            {isLoading && Array.from({ length: NOTIFICATIONS_PAGE_SIZE }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton key={index} height={136} />
            ))}

            {data?.map((notification, idx) => (
              <NotificationCard
                key={notification.id}
                ref={data.length === idx + 1 ? lastItemElementRef : undefined}
                id={notification.id}
                title={notification.title}
                body={notification.body}
                isRead={notification.isRead}
                date={toDate(notification.createdAt)}
                meta={notification.meta}
                onClick={modal.hide}
              />
            ))}

            {isFetchingNextPage && (
              <Center>
                <Loader variant="dots" />
              </Center>
            )}
          </Stack>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
});

export function useNotificationsList() {
  return () => NiceModal.show(NotificationsList);
}
