import { Button, Center, Modal, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconBellRinging } from '@tabler/icons-react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { useIsMobile } from '@/shared/hooks';
import { Icon } from '@/shared/ui';

import { subscribeToPushNotifications } from './lib';

export const PushNotificationsPermissionRequestModal = NiceModal.create(() => {
  const modal = useModal();
  const isMobile = useIsMobile();

  return (
    <Modal
      opened={modal.visible}
      onClose={modal.hide}
      withCloseButton={false}
      closeOnClickOutside={false}
      size="md"
      transitionProps={{
        onExited: modal.remove,
      }}
      fullScreen={isMobile}
      centered
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <Center h="100%" py={16}>
        <Stack gap={24} align="center">
          <ThemeIcon variant="light" radius="xl" size={64}>
            <Icon src={IconBellRinging} size={48} />
          </ThemeIcon>

          <Text size="md" ta="center">
            Before you continue, please update your notification preferences.
          </Text>

          <Button
            onClick={async () => {
              modal.hide();
              subscribeToPushNotifications();
            }}
          >
            Proceed
          </Button>
        </Stack>
      </Center>
    </Modal>
  );
});

export function usePushNotificationsPermissionRequestModal() {
  return () => NiceModal.show(PushNotificationsPermissionRequestModal);
}
