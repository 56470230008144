import { Badge, Box, Group, Text } from '@mantine/core';

import { getRouteApi } from '@tanstack/react-router';

import { RichContentEditor, useRichContentEditor } from '@/widgets/rich-content-editor';
import { TableOfContents } from '@/widgets/table-of-contents';

import { usePageByIdQuery } from '@/entities/page';

import { formatRelativeTime } from '@/shared/lib';

import { ContentPageLayout } from './ContentPageLayout';
import { ContentPageToolbar } from './ContentPageToolbar';

const route = getRouteApi('/_protected/workspaces/$workspaceSlug/pages/$pageId');

export function ContentPageView() {
  const params = route.useParams();
  const { data: page } = usePageByIdQuery({ id: params.pageId });

  const editor = useRichContentEditor({
    content: page.content,
    editable: false,
  });

  return (
    <>
      <ContentPageToolbar />

      <ContentPageLayout
        title={page.title}
        description={page.description}
        content={editor && (
          <RichContentEditor pageId={page.id} editor={editor} />
        )}
        meta={(
          <>
            {page.tags.length > 0 && (
              <Group gap={4}>
                {page.tags.map(tag => (
                  <Badge key={tag.name}>{tag.name}</Badge>
                ))}
              </Group>
            )}

            <Text fz="sm" c="dimmed">
              {`Edited by ${page.updatedBy.name} ${formatRelativeTime(page.updatedAt)}`}
            </Text>
          </>
        )}
        aside={(
          <Box style={{ flex: 1 }}>
            {editor && (
              <TableOfContents contentElement={editor.view.dom} />
            )}
          </Box>
        )}
      />
    </>
  );
}
