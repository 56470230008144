import { ActionIcon, Tooltip } from '@mantine/core';
import { IconPower } from '@tabler/icons-react';

import { useActivateUserMutation, useDeactivateUserMutation } from '@/features/users/activation';

import { Icon } from '@/shared/ui';

export interface UserStatusButtonProps {
  userId: string;
  isActive: boolean;
}

export function UserStatusButton({ userId, isActive }: UserStatusButtonProps) {
  const { mutate: activate, isPending: isActivating } = useActivateUserMutation();
  const { mutate: deactivate, isPending: isDeactivating } = useDeactivateUserMutation();

  const toggle = () => {
    if (isActive) {
      deactivate(userId);
    } else {
      activate(userId);
    }
  };

  return (
    <Tooltip label={isActive ? 'Deactivate user' : 'Activate user'}>
      <ActionIcon
        variant="subtle"
        color={isActive ? 'green' : 'red'}
        loading={isActivating || isDeactivating}
        onClick={toggle}
      >
        <Icon src={IconPower} size={20} />
      </ActionIcon>
    </Tooltip>
  );
}
