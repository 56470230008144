import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { PdfView } from '../ui/PdfView';

export interface PdfOptions {

}

const ID_ATTRIBUTE = 'data-pdf-file-id';

interface SetPdfOptions {
  id: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pdf: {
      setPdf: (options: SetPdfOptions) => ReturnType;
    };
  }
}

export const Pdf = Node.create<PdfOptions>({
  name: 'pdf',
  group: 'block',
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: el => el.getAttribute(ID_ATTRIBUTE),
        renderHTML: attrs => (attrs.id ? { [ID_ATTRIBUTE]: attrs.id } : {}),
      },
    };
  },

  addCommands() {
    return {
      setPdf: ({ id }: SetPdfOptions) => ({ commands }) => commands.insertContent([
        {
          type: 'paragraph',
          content: [],
        },
        {
          type: this.name,
          attrs: { id },
        },
        {
          type: 'paragraph',
          content: [],
        },
      ]),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(PdfView);
  },

  parseHTML() {
    return [
      { tag: `media[${ID_ATTRIBUTE}]` },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['media', HTMLAttributes];
  },
});
