import { infiniteQueryOptions, keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { api, Notifications, queryClient } from '@/shared/api';

export const notificationsQueryKeyFactory = {
  all: () => ['notifications'] as const,
  list: () => [...notificationsQueryKeyFactory.all(), 'list'] as const,
  unreadCount: () => [...notificationsQueryKeyFactory.list(), 'unread'] as const,
  paginatedList: (p: Omit<Notifications.GetNotifications.RequestQuery, 'pagination'> = {}) => [...notificationsQueryKeyFactory.list(), 'paginated', p] as const,
};

export function useUnreadNotificationsCount() {
  return useQuery({
    queryKey: notificationsQueryKeyFactory.unreadCount(),
    queryFn: () => api.notifications.getUnreadCount(),
  });
}

export const NOTIFICATIONS_PAGE_SIZE = 20;

export function notificationsQuery(payload: Omit<Notifications.GetNotifications.RequestQuery, 'pagination'> = {}) {
  return infiniteQueryOptions({
    queryKey: notificationsQueryKeyFactory.paginatedList(payload),
    async queryFn(ctx) {
      const result = await api.notifications.getNotifications({
        ...payload,
        pagination: {
          size: NOTIFICATIONS_PAGE_SIZE,
          cursor: ctx.pageParam,
        },
      });

      await queryClient.invalidateQueries({ queryKey: notificationsQueryKeyFactory.unreadCount() });

      return result;
    },
    placeholderData: keepPreviousData,
    select: data => data?.pages.flatMap(i => i.data) || [],
    initialPageParam: null as null | string,
    getNextPageParam(lastPage, pages) {
      const loadedPagesCount = pages.flatMap(page => page.data).length;

      return loadedPagesCount >= lastPage.total
        ? undefined
        : lastPage.cursor;
    },
  });
}

export function useNotificationQuery(payload: Omit<Notifications.GetNotifications.RequestQuery, 'pagination'> = {}) {
  return useInfiniteQuery(notificationsQuery(payload));
}
