import { createFileRoute } from '@tanstack/react-router';

import { WorkspaceView } from '@/views/workspace';

import { latestUpdatesPagesQuery } from '@/entities/page';
import { workspaceQuery } from '@/entities/workspace';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug/')({
  async loader({ params: { workspaceSlug } }) {
    await Promise.all([
      queryClient.ensureQueryData(workspaceQuery(workspaceSlug)),
      queryClient.ensureQueryData(latestUpdatesPagesQuery({ workspaceSlug })),
    ]);
  },
  component: Component,
});

function Component() {
  const { workspaceSlug } = Route.useParams();

  return <WorkspaceView slug={workspaceSlug} />;
}
