import { PropsWithChildren } from 'react';

import { Box, useMatches } from '@mantine/core';

export interface RenderResponsiveProps extends PropsWithChildren {
  base: (p: PropsWithChildren) => React.ReactNode;
  xs?: (p: PropsWithChildren) => React.ReactNode;
  sm?: (p: PropsWithChildren) => React.ReactNode;
  md?: (p: PropsWithChildren) => React.ReactNode;
  lg?: (p: PropsWithChildren) => React.ReactNode;
  xl?: (p: PropsWithChildren) => React.ReactNode;
}

export function RenderResponsive({ base, xs, sm, md, lg, xl, children }: RenderResponsiveProps) {
  const responsiveOptions = { base, xs, sm, md, lg, xl };
  const existingOptionEntries = Object.entries(responsiveOptions).filter(([_, value]) => value);
  const renderFn = useMatches(Object.fromEntries(existingOptionEntries));

  return (
    <Box renderRoot={renderFn}>
      {children}
    </Box>
  );
}
