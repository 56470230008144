import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import MinMax from 'dayjs/plugin/minMax';
import RelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(MinMax);

export const toDate = (input: dayjs.ConfigType) => dayjs(input).toDate();

export const formatDate = (date: dayjs.ConfigType) => dayjs(date).format('LL');
export const formatShortDate = (date: dayjs.ConfigType) => dayjs(date).format('ll');
export const formatDateTime = (date: dayjs.ConfigType) => dayjs(date).format('LLL');
export const formatShortDateTime = (date: dayjs.ConfigType) => dayjs(date).format('lll');
export const formatRelativeTime = (date: dayjs.ConfigType) => dayjs(date).fromNow();

export const getLatestDate = (...dates: dayjs.ConfigType[]) => dayjs.max(dates.map(dayjs));
