import { createFileRoute, notFound } from '@tanstack/react-router';
import { z } from 'zod';

import { UsersView } from '@/views/admin-users';

import { groupsQuery } from '@/entities/group';
import { usersQuery } from '@/entities/user';

import { queryClient } from '@/shared/api';
import { Permission } from '@/shared/const';
import { hasPermission } from '@/shared/session';

export const Route = createFileRoute('/_protected/admin/users')({
  validateSearch: z.object({
    group: z.string().optional(),
  }),
  async beforeLoad() {
    const canManageUsers = await hasPermission(Permission.ManageUsers);
    if (!canManageUsers)
      throw notFound();
  },
  loaderDeps: ({ search }) => search,
  async loader({ deps }) {
    await Promise.all([
      queryClient.prefetchQuery(groupsQuery()),
      queryClient.prefetchQuery(usersQuery({
        group: deps.group,
      })),
    ]);
  },
  component: UsersView,
});
