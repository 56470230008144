import { ActionIcon, Group } from '@mantine/core';
import { IconPencil, IconTrash, IconUsers } from '@tabler/icons-react';

import { Link } from '@tanstack/react-router';
import { CellContext } from '@tanstack/table-core';

import { useDeleteGroup } from '@/features/groups/delete';
import { useEditGroupModal } from '@/features/groups/edit';

import { groupsQuery } from '@/entities/group';

import { QueryResult } from '@/shared/lib';
import { Icon } from '@/shared/ui';

export function ActionsCell({ row }: CellContext<QueryResult<typeof groupsQuery>[number], unknown>) {
  const { id, availableActions } = row.original;

  const openDeleteModal = useDeleteGroup();
  const openEditModal = useEditGroupModal();

  return (
    <Group gap={4} wrap="nowrap" justify="end">
      <ActionIcon
        variant="subtle"
        color="gray"
        renderRoot={p => <Link {...p} to="/admin/users" search={{ group: id }} />}
      >
        <Icon src={IconUsers} size={20} />
      </ActionIcon>

      <ActionIcon variant="subtle" color="gray" onClick={() => openEditModal(id)}>
        <Icon src={IconPencil} size={20} />
      </ActionIcon>

      {availableActions?.canDelete && (
        <ActionIcon variant="subtle" color="red" onClick={() => openDeleteModal(id)}>
          <Icon src={IconTrash} size={20} />
        </ActionIcon>
      )}
    </Group>
  );
}
