import { ActionIcon, Box, Group, rem, Skeleton, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { usePageAttachmentQuery } from '@/entities/page-attachment';

import { Icon } from '@/shared/ui';

import styles from './PdfView.module.css';

export function PdfView({ node, deleteNode, editor }: NodeViewProps) {
  const { data: attachment, isLoading } = usePageAttachmentQuery(node.attrs.id);

  return (
    <NodeViewWrapper>
      <Box contentEditable={false} my={32}>
        <Box visibleFrom="md" pos="relative">
          {editor.isEditable && (
            <Box pos="absolute" style={{ top: 0, right: rem(-48) }}>
              <ActionIcon size="lg" color="red" variant="light" onClick={deleteNode}>
                <Icon src={IconTrash} size={24} />
              </ActionIcon>
            </Box>
          )}

          <Box
            component="iframe"
            title="pdf"
            src={`/api/file/attachment/${node.attrs.id}`}
            w="100%"
            h="800px"
          />
        </Box>

        <Box
          hiddenFrom="md"
          component="a"
          w="100%"
          href={`/api/file/attachment/${node.attrs.id}`}
          target="_blank"
          className={styles.mobileButton}
        >

          <Group align="flex-start" justify="space-between" wrap="nowrap">
            <Box>
              <Text component="div" className={styles.label} mb={4}>
                Click here to view PDF file
              </Text>

              <Skeleton visible={isLoading}>
                <Text component="div" lineClamp={1}>
                  {attachment?.name}
                </Text>
              </Skeleton>
            </Box>

            {editor.isEditable && (
              <ActionIcon
                flex={1}
                color="red"
                variant="transparent"
                onClick={(e) => {
                  e.preventDefault();
                  deleteNode();
                }}
              >
                <Icon size={20} src={IconTrash} />
              </ActionIcon>
            )}
          </Group>

          <Text mt={16} component="div" className={styles.description}>
            PDF files could not be properly shown on mobile devices. Click to open it in a new tab.
          </Text>
          <Text mt={4} className={styles.description}>
            WARNING: You may need to use swipe gesture to navigate back.
          </Text>
        </Box>
      </Box>
    </NodeViewWrapper>
  );
}
