import { useState } from 'react';

import { Box, CloseButton, Group, Loader, Stack, Tabs, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createSpotlight, Spotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';

import { useNavigate } from '@tanstack/react-router';

import { useWorkspaceQuery } from '@/entities/workspace';

import { formatRelativeTime } from '@/shared/lib';
import { Icon } from '@/shared/ui';

import { useSearchQuery } from '../api';
import { AiSearch } from './AiSearch';

import classes from './SearchSpotlight.module.css';

export const [searchStore, searchHandlers] = createSpotlight();

export interface SearchSpotlightProps {
  workspaceSlug: string;
}

export function SearchSpotlight({ workspaceSlug }: SearchSpotlightProps) {
  const [query, setQuery] = useState('');
  const { data: pages = [], isFetching } = useSearchQuery({ query, workspaceSlug });
  const { data: workspace } = useWorkspaceQuery(workspaceSlug);

  const total = pages.length > 0 ? Number(pages[0].total) : 0;
  const totalText = total === pages.length
    ? `Found ${total} ${total === 1 ? 'page' : 'pages'}`
    : `Found ${total} ${total === 1 ? 'page' : 'pages'}, first ${pages.length} shown`;

  const navigate = useNavigate();
  const navigateToPage = (page: typeof pages[number]) => (
    navigate({
      to: '/workspaces/$workspaceSlug/pages/$pageId',
      params: {
        workspaceSlug: page.workspaceSlug,
        pageId: page.id,
      },
    })
  );

  // There could be multiple search widgets across the app, so we need to reset query on close
  const onClose = () => searchStore.updateState(prev => ({ ...prev, query: '' }));

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const [tab, setTab] = useState<string | null>('search');

  return (
    <Spotlight.Root
      size={800}
      scrollable
      maxHeight={isMobile ? '100dvh' : 600}
      shortcut={['mod + K', '/']}
      store={searchStore}
      query={query}
      fullScreen={isMobile}
      radius={isMobile ? 0 : undefined}
      onQueryChange={setQuery}
      onSpotlightClose={onClose}
    >
      <Tabs variant="pills" h="100%" className={classes.wrapper} value={tab} onChange={setTab}>
        <Group justify="space-between" align="flex-start" p={16} className={classes.header}>
          <div>
            <Title size="h4" mb={12}>
              {`${tab === 'search' ? 'Search' : 'Ask'} in ${workspace!.title}`}
            </Title>

            <Tabs.List>
              <Tabs.Tab value="search">Search</Tabs.Tab>

              <Tabs.Tab value="copilot">Ask Copilot</Tabs.Tab>
            </Tabs.List>
          </div>

          <CloseButton onClick={searchHandlers.close} />
        </Group>

        <Tabs.Panel value="search" mih={1} style={{ flex: 1 }}>
          <Spotlight.Search
            data-autofocus
            placeholder="Search..."
            leftSection={<Icon src={IconSearch} size={20} />}
            rightSection={isFetching && <Loader size={20} />}
          />

          <Spotlight.ActionsList>
            {pages.length === 0
              ? (
                  <Spotlight.Empty>Nothing found</Spotlight.Empty>
                )
              : (
                  <>
                    <Box px={16} pt={4} pb={8}>
                      <Text fz="sm" c="dimmed">
                        {totalText}
                      </Text>
                    </Box>

                    {pages.map(page => (
                      <Spotlight.Action key={page.id} className={classes.action} onClick={() => navigateToPage(page)}>
                        <Stack gap={0}>
                          <Text fw={600}>
                            <span className={classes.dimmedText}>{`${page.workspaceTitle} / `}</span>

                            <span dangerouslySetInnerHTML={{ __html: page.title }} />
                          </Text>

                          {page.description && (
                            <Text className={classes.dimmedText} fz="sm" dangerouslySetInnerHTML={{ __html: page.description }} />
                          )}

                          {page.headline && (
                            <Text mt={4} fz="sm" dangerouslySetInnerHTML={{ __html: `${page.headline} ...` }} />
                          )}

                          <Text mt={8} fz="xs" className={classes.dimmedText}>
                            {`Edited by ${page.updatedBy} ${formatRelativeTime(page.updatedAt)}`}
                          </Text>
                        </Stack>
                      </Spotlight.Action>
                    ))}
                  </>
                )}
          </Spotlight.ActionsList>
        </Tabs.Panel>

        <Tabs.Panel value="copilot" mih={1} style={{ flex: 1 }}>
          <AiSearch />
        </Tabs.Panel>
      </Tabs>
    </Spotlight.Root>
  );
}
