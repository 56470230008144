import { Box, Title } from '@mantine/core';

import { Toolbar } from './Toolbar';
import { UsersTable } from './UsersTable';

export function UsersView() {
  return (
    <>
      <Toolbar />

      <Box pb={40}>
        <Title order={1} mb="xl">
          Users
        </Title>

        <UsersTable />
      </Box>
    </>
  );
}
