import { Group, Loader, MantineColor, Stack, Text, TypographyStylesProvider } from '@mantine/core';

import { formatRelativeTime } from '@/shared/lib';

type MessageBoxProps = {
  author: string;
  color?: MantineColor;
} & (
  | {
    date: string;
    text: string;
    loading?: false;
  }
  | {
    date?: undefined;
    text?: undefined;
    loading: true;
  }
);

export function MessageBox({ author, date, text, color, loading }: MessageBoxProps) {
  return (
    <Stack gap={0}>
      <Group gap={8} align="baseline">
        <Text inherit fw={500} c={color}>
          {author}
        </Text>

        {date && (
          <Text inherit fz="sm" c="dimmed">
            {formatRelativeTime(date)}
          </Text>
        )}
      </Group>

      {loading
        ? (
            <Stack gap={0}>
              <Loader type="dots" />

              <Text fz="sm" c="dimmed">
                It may take up to 20 seconds to get an answer from Copilot. Please wait...
              </Text>
            </Stack>
          )
        : (
            <TypographyStylesProvider
              dangerouslySetInnerHTML={{ __html: text }}
              p={0}
              m={0}
            />
          )}
    </Stack>
  );
}
