import { Anchor, Box, List, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconNews } from '@tabler/icons-react';

import { Link, notFound } from '@tanstack/react-router';

import { useLatestUpdatedPagesQuery } from '@/entities/page';
import { useWorkspaceQuery } from '@/entities/workspace';

import { formatRelativeTime } from '@/shared/lib';
import { Icon } from '@/shared/ui';

import { Toolbar } from './Toolbar';

import classes from './WorkspaceView.module.css';

interface Props {
  slug: string;
}

export function WorkspaceView({ slug }: Props) {
  const { data: workspace } = useWorkspaceQuery(slug);
  if (!workspace) {
    throw notFound();
  }

  const { title, description } = workspace;
  const { data: latestUpdatedPages = [] } = useLatestUpdatedPagesQuery({ workspaceSlug: slug });

  return (
    <>
      <Toolbar />

      <div className={classes.grid}>
        <main className={classes.content}>
          <Title order={1} className="text-wrap">
            {title}
          </Title>

          {description && (
            <Text mt={8} fz="lg" c="dimmed" className="text-wrap">
              {description}
            </Text>
          )}

          <Box mt={40}>
            <Title order={3} mb="md">
              Latest updates
            </Title>

            {latestUpdatedPages.length === 0
              ? (
                  <Text c="dimmed" fz="md" fs="italic">
                    There are no pages in this workspace yet
                  </Text>
                )
              : (
                  <List spacing="sm">
                    {latestUpdatedPages.map(page => (
                      <List.Item
                        key={page.id}
                        icon={(
                          <ThemeIcon variant="light" size={32} radius="xl">
                            <Icon src={IconNews} size={20} />
                          </ThemeIcon>
                        )}
                      >
                        <Stack gap={0}>
                          <Anchor renderRoot={p => (
                            <Link
                              {...p}
                              to="/workspaces/$workspaceSlug/pages/$pageId"
                              params={{
                                workspaceSlug: workspace.slug,
                                pageId: page.id,
                              }}
                            />
                          )}
                          >
                            {page.title}
                          </Anchor>

                          <Text c="dimmed" fz="xs">
                            {`Edited by ${page.updatedBy.name} ${formatRelativeTime(page.updatedAt)}`}
                          </Text>
                        </Stack>
                      </List.Item>
                    ))}
                  </List>
                )}
          </Box>
        </main>
      </div>
    </>
  );
}
