import { Box, Title } from '@mantine/core';

import { GroupsTable } from './GroupsTable';
import { Toolbar } from './Toolbar';

export function GroupsView() {
  return (
    <>
      <Toolbar />

      <Box pb={40}>
        <Title order={1} mb="xl">
          Groups
        </Title>

        <GroupsTable />
      </Box>
    </>
  );
}
