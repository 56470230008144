import { useMutation } from '@tanstack/react-query';

import { api } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

export function useRebuildWorkspaceIndexMutation() {
  return useMutation({
    mutationFn: (p: string) => api.ai.rebuildIndex(p),
    onSuccess() {
      notifySuccess('Workspace AI index has been rebuilt');
    },
  });
}
