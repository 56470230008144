'use client';

import { NavLink, Text } from '@mantine/core';
import { IconCategory } from '@tabler/icons-react';

import { Link, useLocation, useParams } from '@tanstack/react-router';

import { Icon } from '@/shared/ui';

export interface NavigationItemProps {
  title: string;
}

export function RootNavigationItem({ title }: NavigationItemProps) {
  const pathname = useLocation().pathname;
  // TODO: fix types
  const { workspaceSlug } = useParams({ strict: false });
  const href = `/workspaces/${workspaceSlug}`;
  const isActive = pathname === href;

  return (
    <NavLink
      variant="sidebar"
      px="md"
      fw={500}
      renderRoot={p => <Link {...p} to="/workspaces/$workspaceSlug" params={{ workspaceSlug }} />}
      title={title}
      label={<Text inherit lineClamp={1}>{title}</Text>}
      active={isActive}
      leftSection={<Icon src={IconCategory} />}
    />
  );
}
