import { useMutation, useQueryClient } from '@tanstack/react-query';

import { pagesQueryKeyFactory } from '@/entities/page';
import { tagQueryKeyFactory } from '@/entities/tag';

import { api, Pages } from '@/shared/api';

export function useUpdatePageMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Pages.UpdatePage.RequestParams & Pages.UpdatePage.RequestBody) => api.pages.updatePage(payload.id, payload),
    async onSuccess(page) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.lists() }),
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.singleById(page.id) }),
        queryClient.invalidateQueries({ queryKey: tagQueryKeyFactory.list() }),
      ]);
    },
  });
}
