'use client';

import { ActionIcon, Tooltip } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';

import { useSendInvitationEmailMutation } from '@/features/users/create';

import { Icon } from '@/shared/ui';

export interface SendInvitationButtonProps {
  userId: string;
}

export function SendInvitationButton({ userId }: SendInvitationButtonProps) {
  const { mutate, isPending } = useSendInvitationEmailMutation();

  return (
    <Tooltip label="Send invitation email">
      <ActionIcon
        variant="subtle"
        color="gray"
        loading={isPending}
        onClick={() => mutate(userId)}
      >
        <Icon src={IconMail} size={20} />
      </ActionIcon>
    </Tooltip>
  );
}
