import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';

import { BlankLayout } from '@/widgets/layouts/blank';

import { useCurrentUser } from '@/shared/session';

export const Route = createFileRoute('/auth')({
  component: Component,
});

function Component() {
  const user = useCurrentUser();
  if (user && user.isActive) {
    return <Navigate to="/" replace />;
  }

  return (
    <BlankLayout>
      <Outlet />
    </BlankLayout>
  );
}
