import { ReactNode } from 'react';

import { Box, ScrollArea, Stack, Text, Title, TypographyStylesProvider } from '@mantine/core';

import clsx from 'clsx';

import { ContentPageBreadcrumbs } from '@/widgets/content-page-breadcrumbs';

import classes from './style.module.css';

export interface ContentPageProps {
  title: ReactNode;
  description: ReactNode;
  meta: ReactNode;
  content: ReactNode;
  aside?: ReactNode;
  showBreadcrumbs?: boolean;
}

export function ContentPageLayout({
  showBreadcrumbs = false,
  title,
  description,
  meta,
  content,
  aside,
}: ContentPageProps) {
  return (
    <div className={classes.grid}>
      <div className={classes.content}>
        {showBreadcrumbs && (
          <ContentPageBreadcrumbs />
        )}

        <Stack gap={8} mb={24}>
          <Title order={1} className="text-wrap">
            {title}
          </Title>

          {description && (
            typeof description === 'string'
              ? (
                  <Text fz="lg" c="dimmed" className="text-wrap">
                    {description}
                  </Text>
                )
              : description
          )}
        </Stack>

        {meta && (
          <Stack className="no-print" gap={8}>
            {meta}
          </Stack>
        )}

        <TypographyStylesProvider data-toc-source mt={36} className={classes.typographyProvider} pl={0}>
          {content}
        </TypographyStylesProvider>
      </div>

      {aside && (
        <Box component="aside" visibleFrom="lg" className={clsx('no-print', classes.aside)}>
          <ScrollArea.Autosize className={classes.scrollArea} offsetScrollbars>
            {aside}
          </ScrollArea.Autosize>
        </Box>
      )}
    </div>
  );
}
