import { Button, Loader, Menu } from '@mantine/core';
import { IconDownload, IconPencil, IconTrash, IconWand } from '@tabler/icons-react';

import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { ContentPageBreadcrumbs } from '@/widgets/content-page-breadcrumbs';
import { PageHeader } from '@/widgets/layouts/app';

import { useDeletePage } from '@/features/pages/delete';
import { useExportPageAsPdf } from '@/features/pages/export-pdf';

import { usePageByIdQuery } from '@/entities/page';

import { Permission } from '@/shared/const';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

import { DisallowDeleteTooptip } from './DisallowDeleteTooptip';

const route = getRouteApi('/_protected/workspaces/$workspaceSlug/pages/$pageId');

export function ContentPageToolbar() {
  const params = route.useParams();
  const navigate = useNavigate();
  const canEditPage = usePermission(Permission.WritePages);

  const { data: page } = usePageByIdQuery({ id: params.pageId });

  const hasChildren = page.children.length > 0;
  const { canDeletePage, deletePage } = useDeletePage({
    redirectToParent: true,
  });

  const goToEdit = () => {
    navigate({
      to: '/workspaces/$workspaceSlug/pages/$pageId/edit',
      params: {
        workspaceSlug: params.workspaceSlug,
        pageId: page.id,
      },
    });
  };

  const goToSummary = () => {
    navigate({
      to: '/workspaces/$workspaceSlug/pages/$pageId/summary',
      params: {
        workspaceSlug: params.workspaceSlug,
        pageId: page.id,
      },

    });
  };

  const {
    mutate: exportPageToPdf,
    isPending: isExportingPageToPdf,
  } = useExportPageAsPdf();

  return (
    <PageHeader
      desktopLeftSection={<ContentPageBreadcrumbs />}
      mobileToolbar={<ContentPageBreadcrumbs />}
      mobileMenu={(
        <>
          {canEditPage && (
            <Menu.Item leftSection={<Icon src={IconPencil} />} onClick={goToEdit}>
              Edit
            </Menu.Item>
          )}

          <Menu.Item leftSection={<Icon src={IconWand} />} onClick={goToSummary}>
            Summarize
          </Menu.Item>

          <Menu.Item
            closeMenuOnClick={false}
            disabled={isExportingPageToPdf}
            leftSection={isExportingPageToPdf ? <Loader size={16} /> : <Icon src={IconDownload} />}
            onClick={() => exportPageToPdf(page.id)}
          >
            Export to PDF
          </Menu.Item>

          {canDeletePage && (
            <Menu.Item
              disabled={hasChildren}
              variant="light"
              color="red"
              leftSection={<Icon src={IconTrash} />}
              onClick={() => deletePage(page.id)}
            >
              Delete
            </Menu.Item>
          )}
        </>
      )}
      desktopActions={(
        <>
          {canEditPage && (
            <Button
              size="xs"
              variant="subtle"
              color="default"
              leftSection={<Icon src={IconPencil} />}
              onClick={goToEdit}
            >
              Edit
            </Button>
          )}

          <Button
            size="xs"
            variant="subtle"
            color="default"
            leftSection={<Icon src={IconWand} />}
            onClick={goToSummary}
          >
            Summarize
          </Button>

          <Button
            size="xs"
            variant={isExportingPageToPdf ? 'light' : 'subtle'}
            color={isExportingPageToPdf ? 'blue' : 'default'}
            leftSection={<Icon src={IconDownload} />}
            loading={isExportingPageToPdf}
            onClick={() => exportPageToPdf(page.id)}
          >
            Export to PDF
          </Button>

          {canDeletePage && (
            <DisallowDeleteTooptip disabled={!hasChildren} position="bottom">
              <Button
                size="xs"
                variant="subtle"
                color="red"
                leftSection={<Icon src={IconTrash} />}
                disabled={hasChildren}
                onClick={() => deletePage(page.id)}
              >
                Delete
              </Button>
            </DisallowDeleteTooptip>
          )}
        </>
      )}
    />
  );
}
