import { useEffect, useState } from 'react';

import { CheckIcon, Combobox, Group, Loader, Pill, PillsInput, PillsInputProps, useCombobox } from '@mantine/core';
import { IconTags } from '@tabler/icons-react';

import { z } from 'zod';

import { useTagsQuery } from '@/entities/tag';

import { Icon } from '@/shared/ui';

const CREATE_OPTION_VAL = '$create';

export interface SearchableTagsInputProps extends Pick<PillsInputProps, 'label' | 'error' | 'onFocus' | 'onBlur'> {
  value?: string[];
  onChange?: (value: string[]) => void;
}

export function SearchableTagsInput({
  label,
  // eslint-disable-next-line react/no-unstable-default-props
  value = [],
  error,
  onFocus,
  onBlur,
  onChange,
}: SearchableTagsInputProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const { data = [], isFetching } = useTagsQuery({
    filter: { search },
  });

  const { success: isSearchValid } = z.string().min(3).safeParse(search.trim());

  useEffect(() => {
    combobox.selectFirstOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, data]);

  const foundTags = data.map(i => i.name);
  const lcValues = value.map(v => v.toLowerCase());
  const lcData = foundTags.map(v => v.toLowerCase());
  const exactOptionMatch = lcData.includes(search)
    || lcValues.includes(search);

  const handleValueRemove = (val: string) => onChange?.(value.filter(v => v !== val));
  const handleValueSelect = (val: string) => {
    setSearch('');

    if (val === CREATE_OPTION_VAL) {
      onChange?.([...value, search]);
    } else {
      onChange?.(value.includes(val) ? value.filter(v => v !== val) : [...value, val]);
    }
  };

  const values = value.map(item => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ));

  const options = foundTags
    .filter(item => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map(item => (
      <Combobox.Option value={item} key={item} active={value.includes(item)}>
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}

          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          error={error}
          leftSection={isFetching ? <Loader size={16} /> : <Icon src={IconTags} size={20} />}
          onClick={() => combobox.openDropdown()}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={(e) => {
                  onFocus?.(e);
                  combobox.openDropdown();
                }}
                onBlur={(e) => {
                  onBlur?.(e);
                  combobox.closeDropdown();
                }}
                value={search}
                placeholder="Search tags"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={300} style={{ overflow: 'auto' }}>
          {!exactOptionMatch && isSearchValid && (
            <Combobox.Option value={CREATE_OPTION_VAL}>
              {`+ Create ${search}`}
            </Combobox.Option>
          )}

          {options}

          {options.length === 0 && (exactOptionMatch || !isSearchValid) && (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
