import { api } from '@/shared/api';
import { defineSuspenseQuery } from '@/shared/lib';

export const userSettingsQueryKeyFactory = {
  all: () => ['user-settings'] as const,
};

export const [useUserSettingsQuery, userSettingsQuery] = defineSuspenseQuery(() => ({
  queryKey: userSettingsQueryKeyFactory.all(),
  queryFn: () => api.userSettings.get(),
}));
