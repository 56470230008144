import { Editor, Extension } from '@tiptap/core';
import { kebabCase } from 'lodash-es';

function updateHeadings(editor: Editor) {
  const transaction = editor.state.tr;
  const headings: string[] = [];

  editor.state.doc.descendants((node, pos) => {
    if (node.type.name === 'heading') {
      let id = kebabCase(node.textContent);
      if (headings.includes(id)) {
        id += `-${headings.length}`;
      }

      headings.push(id);

      if (node.attrs.id !== id) {
        transaction.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          id,
        });
      }
    }
  });

  transaction.setMeta('addToHistory', false);
  transaction.setMeta('preventUpdate', true);

  editor.view.dispatch(transaction);
}

export const HeadingId = Extension.create({
  name: 'headingId',
  addGlobalAttributes() {
    return [
      {
        types: ['heading'],
        attributes: {
          id: {
            default: null,
          },
        },
      },
    ];
  },
  onCreate() {
    updateHeadings(this.editor);
  },
  onUpdate() {
    updateHeadings(this.editor);
  },
});
