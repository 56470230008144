import { useEffect, useRef, useState } from 'react';

import { ActionIcon, Box, ScrollArea, Stack, Textarea } from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import { IconSend } from '@tabler/icons-react';

import { useMutation } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';

import { useWorkspaceQuery } from '@/entities/workspace';

import { api } from '@/shared/api';
import { Icon } from '@/shared/ui';

import { MessageBox } from './MessageBox';

import classes from './AiSearch.module.css';

interface Message {
  date: string;
  text: string;
  isAi: boolean;
}

export function AiSearch() {
  // TODO: fix types
  const { workspaceSlug } = useParams({ strict: false });
  const { data: workspace } = useWorkspaceQuery(workspaceSlug!);

  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useSessionStorage<Message[]>({
    key: `ai-search-conversation-${workspace?.id}`,
    defaultValue: [],
  });

  const addMessage = (text: string, isAi = false) => {
    setMessages([...messages, {
      date: new Date().toISOString(),
      text,
      isAi,
    }]);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (p: string) => api.ai.ask({
      prompt: p,
      workspaceId: workspace!.id,
    }),
    onSuccess({ answer }) {
      addMessage(answer, true);
    },
  });

  const viewport = useRef<HTMLDivElement>(null);
  useEffect(() => {
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  const handleMessageSend = () => {
    const preparedPrompt = prompt.trim();
    if (preparedPrompt.length === 0) {
      return;
    }

    addMessage(preparedPrompt);
    setPrompt('');
    mutate(preparedPrompt);
  };

  return (
    <Stack gap={0} h="100%" className={classes.root}>
      <Box style={{ flex: 1 }} mih={1}>
        <ScrollArea.Autosize viewportRef={viewport} h="100%">
          <Stack p={16}>
            {messages.map(message => (
              <MessageBox
                key={message.date}
                date={message.date}
                text={message.text}
                author={message.isAi ? 'Copilot' : 'You'}
                color={message.isAi ? 'blue' : 'grape'}
              />
            ))}

            {isPending && (
              <MessageBox author="Copilot" color="blue" loading />
            )}
          </Stack>
        </ScrollArea.Autosize>
      </Box>

      <Textarea
        data-autofocus
        autosize
        size="md"
        rows={1}
        maxRows={4}
        placeholder="Enter your question..."
        classNames={{
          root: classes.inputRoot,
          input: classes.inputField,
          section: classes.inputSection,
        }}
        value={prompt}
        disabled={isPending}
        onChange={e => setPrompt(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            handleMessageSend();
          }
        }}
        rightSection={(
          <ActionIcon size="lg" variant="transparent" disabled={isPending} onClick={handleMessageSend}>
            <Icon src={IconSend} size={24} />
          </ActionIcon>
        )}
      />
    </Stack>
  );
}
