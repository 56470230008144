import { CSSProperties, useEffect } from 'react';

import { ActionIcon, Badge, Box, Center, Group, Loader, NavLink, Text, Tooltip } from '@mantine/core';
import { IconChevronRight, IconPlus, IconPointFilled } from '@tabler/icons-react';

import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate, useParams } from '@tanstack/react-router';
import clsx from 'clsx';

import { useCreatePageMutation } from '@/features/pages/create';

import { useParentPagesQuery } from '@/entities/page';

import { Permission } from '@/shared/const';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

import { INDENTATION_WIDTH } from '../const';
import { useDndDataContext } from '../context/DndDataContext';
import { useItemOpenState } from '../hooks/use-item-open-state';

import classes from './NavigationItem.module.css';

export interface NavigationItemProps {
  id: string;
  title: string;
  isFolder: boolean;
  isDraft?: boolean;
  depth?: number;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) => (!(isSorting || wasDragging));

export function NavigationItem({ title, isFolder, isDraft, id, depth = 0 }: NavigationItemProps) {
  // TODO: fix types
  const { workspaceSlug, pageId } = useParams({ strict: false });
  const { data: parentPages = [] } = useParentPagesQuery({
    pageId: pageId!,
    enabled: !!pageId,
  });

  const isParentOfCurrentPage = parentPages.map(i => i.id).includes(id);
  const mustOpen = isParentOfCurrentPage && isFolder;
  const [isOpened, { toggle, open }] = useItemOpenState(id, mustOpen);

  useEffect(() => {
    if (mustOpen)
      open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustOpen]);

  const isActive = pageId === id;
  const { loadingMap } = useDndDataContext();
  const isLoading = loadingMap[id] ?? false;

  const navigate = useNavigate();
  const handleClick = () => {
    navigate({
      to: '/workspaces/$workspaceSlug/pages/$pageId',
      params: {
        workspaceSlug: workspaceSlug!,
        pageId: id,
      },
    });
    if (isFolder) {
      open();
    }
  };

  const canWritePages = usePermission(Permission.WritePages);
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled: !canWritePages,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
  };

  const leftSection = (() => {
    if (!isFolder) {
      return (
        <Center w={16} h={16}>
          <Icon src={IconPointFilled} size={8} />
        </Center>
      );
    }

    if (isLoading)
      return <Loader size={16} />;

    return (
      <Icon
        src={IconChevronRight}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
        className={classes.chevron}
        style={{
          transform: isOpened ? 'rotate(-90deg)' : 'none',
        }}
      />
    );
  })();

  const preparedTitle = title || 'Untitled';

  const createPageMutation = useCreatePageMutation();
  const createPage = async () => {
    const page = await createPageMutation.mutateAsync({
      workspaceSlug: workspaceSlug!,
      parentId: id,
    });

    navigate({
      to: '/workspaces/$workspaceSlug/pages/$pageId/edit',
      params: { workspaceSlug: workspaceSlug!, pageId: page.id },
    });
  };

  return (
    <Box ref={setDroppableNodeRef} pl={depth * INDENTATION_WIDTH}>
      <NavLink
        ref={setDraggableNodeRef}
        variant="sidebar"
        classNames={classes}
        className={clsx([
          isSorting && classes.sorting,
          isDragging && classes.dragging,
          isDraft && classes.draft,
        ])}
        title={preparedTitle}
        label={(
          <Text inherit lineClamp={1}>
            {preparedTitle}
          </Text>
        )}
        onClick={handleClick}
        active={isActive}
        leftSection={leftSection}
        rightSection={(
          <Group gap={2}>
            {isDraft && <Badge variant="light" color="gray" size="sm">Draft</Badge>}
            <Group gap={2} className={classes.actions}>
              {canWritePages && (
                <Tooltip label="Create page" position="right">
                  <ActionIcon
                    variant="subtle"
                    size="sm"
                    loading={createPageMutation.isPending}
                    disabled={createPageMutation.isPending}
                    onClick={(e) => {
                      e.stopPropagation();
                      createPage();
                    }}
                  >
                    <Icon src={IconPlus} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          </Group>
        )}
        disableRightSectionRotation
        style={style}
        {...listeners}
        {...attributes}
      />
    </Box>
  );
}
