import { Button, Loader, Menu } from '@mantine/core';
import { IconPencil, IconRefresh, IconTrash } from '@tabler/icons-react';

import { notFound, useNavigate, useParams } from '@tanstack/react-router';

import { ContentPageBreadcrumbs } from '@/widgets/content-page-breadcrumbs';
import { PageHeader } from '@/widgets/layouts/app';

import { useRebuildWorkspaceIndexMutation } from '@/features/ai-search/rebuild-workspace-index';
import { useDeleteWorkspace } from '@/features/workspaces/delete';
import { useEditWorkspace } from '@/features/workspaces/edit';

import { useWorkspaceQuery } from '@/entities/workspace';

import { Permission } from '@/shared/const';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

import { DisallowDeleteTooptip } from './DisallowDeleteTooptip';

export function Toolbar() {
  // TODO: fix types
  const { workspaceSlug } = useParams({ strict: false });
  const { data: workspace } = useWorkspaceQuery(workspaceSlug!);
  if (!workspace) {
    throw notFound();
  }

  const hasPages = workspace._count.pages > 0;
  const { editWorkspace, canEditWorkspace } = useEditWorkspace();

  const navigate = useNavigate();
  const { deleteWorkspace, canDeleteWorkspace } = useDeleteWorkspace({
    onSuccess: () => {
      navigate({ to: '/', replace: true });
    },
  });

  const canRebuildIndex = usePermission(Permission.Everything);
  const rebuildAiIndex = useRebuildWorkspaceIndexMutation();

  return (
    <PageHeader
      desktopLeftSection={<ContentPageBreadcrumbs />}
      mobileToolbar={<ContentPageBreadcrumbs />}
      desktopActions={canEditWorkspace && (
        <>
          <Button
            size="xs"
            variant="subtle"
            color="default"
            leftSection={<Icon src={IconPencil} />}
            onClick={() => editWorkspace(workspace.slug)}
          >
            Edit
          </Button>

          {canRebuildIndex && (
            <Button
              size="xs"
              variant={rebuildAiIndex.isPending ? 'light' : 'subtle'}
              color={rebuildAiIndex.isPending ? 'blue' : 'default'}
              loading={rebuildAiIndex.isPending}
              leftSection={<Icon src={IconRefresh} />}
              onClick={() => rebuildAiIndex.mutate(workspace.slug)}
            >
              Rebuild AI index
            </Button>
          )}

          {canDeleteWorkspace && (
            <DisallowDeleteTooptip disabled={!hasPages} position="bottom">
              <Button
                size="xs"
                variant="subtle"
                color="red"
                leftSection={<Icon src={IconTrash} />}
                disabled={hasPages}
                onClick={() => deleteWorkspace(workspace.id)}
              >
                Delete
              </Button>
            </DisallowDeleteTooptip>
          )}
        </>
      )}
      mobileMenu={canEditWorkspace && (
        <>
          <Menu.Item leftSection={<Icon src={IconPencil} />} onClick={() => editWorkspace(workspace.slug)}>
            Edit
          </Menu.Item>

          {canRebuildIndex && (
            <Menu.Item
              closeMenuOnClick={false}
              leftSection={rebuildAiIndex.isPending ? <Loader size={16} /> : <Icon src={IconRefresh} />}
              onClick={() => rebuildAiIndex.mutate(workspace.slug)}
            >
              Rebuild AI index
            </Menu.Item>
          )}

          {canDeleteWorkspace && (
            <Menu.Item
              disabled={hasPages}
              variant="light"
              color="red"
              leftSection={<Icon src={IconTrash} />}
              onClick={() => deleteWorkspace(workspace.id)}
            >
              Delete
            </Menu.Item>
          )}
        </>
      )}
    />
  );
}
