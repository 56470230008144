'use client';

import { ActionIcon, Box, Group, Loader, TextInput } from '@mantine/core';
import { IconPencil, IconSearch, IconTrash } from '@tabler/icons-react';

import { useNavigate, useSearch } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';

import { useDeleteTag } from '@/features/tags/delete';
import { useEditTag } from '@/features/tags/edit';

import { tagsQuery, useTagsQuery } from '@/entities/tag';

import { QueryResult } from '@/shared/lib';
import { DataTable, Icon } from '@/shared/ui';

const colDef = createColumnHelper<QueryResult<typeof tagsQuery>['data'][number]>();

export function TagsTable() {
  const { search } = useSearch({ strict: false });
  const navigate = useNavigate();

  const {
    data = [],
    isLoading,
    isPlaceholderData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useTagsQuery({
    filter: {
      search,
    },
  });

  const { canDeleteTag, deleteTag } = useDeleteTag();
  const { canEditTag, editTag } = useEditTag();

  const columns = [
    colDef.display({
      id: 'name',
      header: 'Name',
      minSize: 180,
      cell: ({ row }) => row.original.name,
    }),
    colDef.display({
      id: 'pages',
      header: 'Pages',
      minSize: 80,
      cell: ({ row }) => row.original._count.pages,
    }),
    colDef.display({
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <Group gap={4} wrap="nowrap" justify="end">
          {canEditTag && (
            <ActionIcon variant="subtle" color="gray" onClick={() => editTag(row.original.id)}>
              <Icon src={IconPencil} size={20} />
            </ActionIcon>
          )}

          {canDeleteTag && (
            <ActionIcon variant="subtle" color="red" onClick={() => deleteTag(row.original.id)}>
              <Icon src={IconTrash} size={20} />
            </ActionIcon>
          )}
        </Group>
      ),
    }),
  ];

  return (
    <Box>
      <TextInput
        mb="sm"
        size="md"
        placeholder="Search"
        leftSection={isFetching && isPlaceholderData ? <Loader size="xs" /> : <Icon src={IconSearch} />}
        value={search}
        onChange={e => navigate({
          to: '.',
          search: { search: e.currentTarget.value || undefined },
          replace: true,
        })}
      />

      <DataTable
        data={data}
        columns={columns}
        isLoading={isLoading || isFetchingNextPage}
        hasNextPage={hasNextPage}
        onLoadMore={fetchNextPage}
      />
    </Box>
  );
}
