import { RichTextEditor } from '@mantine/tiptap';
import {
  IconColumnInsertLeft,
  IconColumnInsertRight,
  IconColumnRemove,
  IconFileTypePdf,
  IconLayoutBoardSplit,
  IconRowInsertBottom,
  IconRowInsertTop,
  IconRowRemove,
  IconTable,
  IconTableColumn,
  IconTableOff,
  IconTableRow,
} from '@tabler/icons-react';

import { BubbleMenu, Editor } from '@tiptap/react';
import clsx from 'clsx';

import { Icon } from '@/shared/ui';

import { useUploadPageAttachment } from '@/features/pages/upload-attachment/hooks';

import classes from './RichContentEditor.module.css';

export interface RichContentEditorProps {
  pageId: string;
  editor: Editor;
}

export function RichContentEditor({ pageId, editor }: RichContentEditorProps) {
  const uploadAttachment = useUploadPageAttachment(pageId);
  const insertPdfAttachment = async () => {
    const attachment = await uploadAttachment();
    editor?.commands.setPdf({ id: attachment.id });
  };

  return (
    <RichTextEditor
      editor={editor}
      withTypographyStyles={false}
      classNames={{
        root: classes.root,
        toolbar: clsx(classes.toolbar, editor?.isFocused && classes.focus),
      }}
    >
      {editor?.isEditable && (
        <RichTextEditor.Toolbar sticky>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H2 />

            <RichTextEditor.H3 />

            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />

            <RichTextEditor.Hr />

            <RichTextEditor.BulletList />

            <RichTextEditor.OrderedList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control
              title="Table"
              onClick={() => editor?.commands.insertTable({
                rows: 1,
                cols: 2,
                withHeaderRow: false,
              })}
            >
              <Icon src={IconTable} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Remove table"
              onClick={() => editor?.commands.deleteTable()}
            >
              <Icon src={IconTableOff} />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control
              title="Toggle header row"
              onClick={() => editor?.commands.toggleHeaderRow()}
            >
              <Icon src={IconTableRow} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Toggle header column"
              onClick={() => editor?.commands.toggleHeaderColumn()}
            >
              <Icon src={IconTableColumn} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Merge or split cells"
              onClick={() => editor?.commands.mergeOrSplit()}
            >
              <Icon src={IconLayoutBoardSplit} />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control
              title="Insert column before"
              onClick={() => editor?.commands.addColumnBefore()}
            >
              <Icon src={IconColumnInsertLeft} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Insert column after"
              onClick={() => editor?.commands.addColumnAfter()}
            >
              <Icon src={IconColumnInsertRight} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Remove column"
              onClick={() => editor?.commands.deleteColumn()}
            >
              <Icon src={IconColumnRemove} />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control
              title="Insert row before"
              onClick={() => editor?.commands.addRowBefore()}
            >
              <Icon src={IconRowInsertTop} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Insert row after"
              onClick={() => editor?.commands.addRowAfter()}
            >
              <Icon src={IconRowInsertBottom} />
            </RichTextEditor.Control>

            <RichTextEditor.Control
              title="Remove row"
              onClick={() => editor?.commands.deleteRow()}
            >
              <Icon src={IconRowRemove} />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control
              title="Insert PDF file"
              onClick={insertPdfAttachment}
            >
              <Icon src={IconFileTypePdf} />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}

      {editor?.isEditable && (
        <BubbleMenu editor={editor} className={classes.bubbleMenu}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />

            <RichTextEditor.Italic />

            <RichTextEditor.Underline />

            <RichTextEditor.Strikethrough />

            <RichTextEditor.ClearFormatting />

            <RichTextEditor.Highlight />

            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />

            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>
        </BubbleMenu>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  );
}
