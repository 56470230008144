import { ActionIcon, Badge, Button, Divider, Menu, Stack } from '@mantine/core';
import { IconArrowBackUp, IconCheck, IconChecklist, IconChevronDown, IconTrash } from '@tabler/icons-react';

import { getRouteApi, Link, useBlocker, useNavigate } from '@tanstack/react-router';

import { ContentPageBreadcrumbs } from '@/widgets/content-page-breadcrumbs';
import { PageHeader } from '@/widgets/layouts/app';
import { RichContentEditor, useRichContentEditor } from '@/widgets/rich-content-editor';
import { SearchableTagsInput } from '@/widgets/searchable-tags-input';

import { useDeletePage } from '@/features/pages/delete';
import { usePublishPageMutation } from '@/features/pages/publish';
import { useUpdatePageMutation } from '@/features/pages/update';

import { usePageByIdQuery } from '@/entities/page';

import { useAsyncForm } from '@/shared/hooks';
import { Icon } from '@/shared/ui';

import { ContentPageLayout } from './ContentPageLayout';
import { DescriptionInput } from './DescriptionInput';
import { DisallowDeleteTooptip } from './DisallowDeleteTooptip';
import { TitleInput } from './TitleInput';

const route = getRouteApi('/_protected/workspaces/$workspaceSlug/pages/$pageId');

export function ContentPageEditView() {
  const params = route.useParams();
  const { data: page } = usePageByIdQuery({ id: params.pageId });
  const hasChildren = page.children.length > 0;

  const navigate = useNavigate();
  const form = useAsyncForm({
    initialValues: {
      title: page.title,
      content: page.content,
      description: page.description,
      tags: page.tags.map(i => i.name),
    },
  });

  const navigateToPage = () => {
    navigate({
      ignoreBlocker: true,
      to: '/workspaces/$workspaceSlug/pages/$pageId',
      params: {
        workspaceSlug: params.workspaceSlug,
        pageId: params.pageId,
      },
    });
  };

  useBlocker({
    // eslint-disable-next-line no-alert
    blockerFn: () => window.confirm('You have unsaved changes. Are you sure you want to leave this page?'),
    condition: form.isDirty(),
  });

  const { mutateAsync, isPending: isSaving } = useUpdatePageMutation();
  const handleSave = async () => {
    await form.onSubmit(async (values) => {
      await mutateAsync({ id: page.id, ...values });
      form.resetDirty(values);

      if (!page.isDraft) {
        navigateToPage();
      }
    })();
  };

  const publishMutation = usePublishPageMutation();
  const handlePublish = async () => {
    await handleSave();

    await form.onSubmit(async (values) => {
      await mutateAsync({ id: page.id, ...values });
      await publishMutation.mutateAsync({ id: page.id });
      form.resetDirty(values);
      navigateToPage();
    })();
  };

  const { canDeletePage, deletePage } = useDeletePage({ redirectToParent: true });

  const editor = useRichContentEditor({
    content: form.values.content,
    onChange: (content) => {
      form.setFieldValue('content', content);
    },
  });

  return (
    <>
      <PageHeader
        mobileToolbar={<ContentPageBreadcrumbs />}
        desktopLeftSection={<ContentPageBreadcrumbs />}
        mobileActions={(
          <>
            <ActionIcon size="lg" variant="transparent" loading={isSaving} onClick={handleSave}>
              <Icon src={IconCheck} size={24} />
            </ActionIcon>

            {!page.isDraft && (
              <ActionIcon
                size="lg"
                variant="transparent"
                renderRoot={p => (
                  <Link
                    {...p}
                    to="/workspaces/$workspaceSlug/pages/$pageId"
                    params={{
                      workspaceSlug: params.workspaceSlug,
                      pageId: params.pageId,
                    }}
                  />
                )}
              >
                <Icon src={IconArrowBackUp} size={24} />
              </ActionIcon>
            )}
          </>
        )}
        mobileMenu={(
          <>
            {page.isDraft && (
              <Menu.Item leftSection={<Icon src={IconChecklist} />} onClick={handlePublish}>
                Save & Publish
              </Menu.Item>
            )}

            {canDeletePage && (
              <Menu.Item leftSection={<Icon src={IconTrash} />} onClick={() => deletePage(page.id)}>
                Delete
              </Menu.Item>
            )}
          </>
        )}
        desktopActions={(
          <>
            <Button.Group>
              <Button size="xs" leftSection={<Icon src={IconCheck} />} loading={isSaving} onClick={handleSave}>
                Save
              </Button>

              {page.isDraft && (
                <>
                  <Divider orientation="vertical" />

                  <Menu position="bottom-end">
                    <Menu.Target>
                      <Button size="xs" px={8}>
                        <Icon src={IconChevronDown} size={16} />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item
                        disabled={isSaving || publishMutation.isPending}
                        leftSection={<Icon src={IconChecklist} />}
                        onClick={handlePublish}
                      >
                        Save & Publish
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </>
              )}
            </Button.Group>

            {!page.isDraft && (
              <Button
                renderRoot={p => (
                  <Link
                    {...p}
                    to="/workspaces/$workspaceSlug/pages/$pageId"
                    params={{
                      workspaceSlug: params.workspaceSlug,
                      pageId: params.pageId,
                    }}
                  />
                )}
                variant="transparent"
                color="default"
                size="xs"
                leftSection={<Icon src={IconArrowBackUp} />}
              >
                Cancel
              </Button>
            )}

            {canDeletePage && (
              <DisallowDeleteTooptip disabled={!hasChildren} position="bottom">
                <Button
                  variant="transparent"
                  color="red"
                  size="xs"
                  disabled={hasChildren}
                  leftSection={<Icon src={IconTrash} />}
                  onClick={() => deletePage(page.id)}
                >
                  Delete
                </Button>
              </DisallowDeleteTooptip>
            )}
          </>
        )}
      />

      <ContentPageLayout
        title={<TitleInput {...form.getInputProps('title')} />}
        description={(
          <Stack gap="xs">
            <DescriptionInput {...form.getInputProps('description')} />
            {page.isDraft && (
              <Badge variant="default" size="xl">
                Draft
              </Badge>
            )}
          </Stack>
        )}
        meta={<SearchableTagsInput label="Tags" {...form.getInputProps('tags')} />}
        content={editor && (
          <RichContentEditor pageId={page.id} editor={editor} />
        )}
      />
    </>
  );
}
