'use client';

import { ActionIcon, Button } from '@mantine/core';
import { IconUsersPlus } from '@tabler/icons-react';

import { AdminBreadcrumbs } from '@/widgets/admin-breadcrumbs';
import { PageHeader } from '@/widgets/layouts/app';

import { useNewGroupModal } from '@/features/groups/create';

import { Icon } from '@/shared/ui';

export function Toolbar() {
  const openModal = useNewGroupModal();

  const breadcrumbs = <AdminBreadcrumbs links={[{ title: 'Groups', href: '/admin/groups', isActive: true }]} />;

  return (
    <PageHeader
      desktopLeftSection={breadcrumbs}
      mobileToolbar={breadcrumbs}
      mobileActions={(
        <ActionIcon size="lg" variant="transparent" onClick={openModal}>
          <Icon src={IconUsersPlus} size={24} />
        </ActionIcon>
      )}
      desktopActions={(
        <Button size="xs" leftSection={<Icon src={IconUsersPlus} />} onClick={openModal}>
          Add group
        </Button>
      )}
    />
  );
}
