import { useCallback, useRef } from 'react';

export interface UseInfiniteScrollRefParams {
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export function useInfiniteScrollRef<T extends HTMLElement = HTMLDivElement>({ isFetchingNextPage, hasNextPage, fetchNextPage }: UseInfiniteScrollRefParams) {
  const observer = useRef<IntersectionObserver>();

  return useCallback((node: T) => {
    if (isFetchingNextPage) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);
}
