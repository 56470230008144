import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { FlattenedNavigationItem, NavigationItem } from '../types';

export function flatten(
  childrenMap: Record<string, NavigationItem[]>,
  activeId: string | null = null,
  parentId: string | null = null,
  depth = 0,
): FlattenedNavigationItem[] {
  if (depth >= 10) {
    console.error('Maximum depth exceeded while flattening navigation items');
    return [];
  }

  const rootItems = childrenMap[parentId || 'root'] ?? [];

  return rootItems.reduce<FlattenedNavigationItem[]>((acc, item, index) => {
    const formattedItem = { ...item, parentId, depth, index };
    const children = item.id !== activeId
      ? flatten(childrenMap, activeId, item.id, depth + 1)
      : [];

    return [...acc, formattedItem, ...children];
  }, []);
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedNavigationItem }) {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedNavigationItem }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items: FlattenedNavigationItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);

  const nextItem = newItems[overItemIndex + 1];
  const previousItem = newItems[overItemIndex - 1];

  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({ previousItem });
  const minDepth = getMinDepth({ nextItem });

  let depth = projectedDepth;
  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find(item => item.depth === depth)?.parentId;

    return newParent ?? null;
  }

  const parentId = getParentId();
  const oldPreviousSibling = items
    .slice(0, activeItemIndex)
    .reverse()
    .find(i => i.parentId === (parentId ?? 'root'));
  const newPreviousSibling = newItems
    .slice(0, overItemIndex)
    .reverse()
    .find(i => i.parentId === (parentId ?? 'root'));

  return {
    previousItem,
    depth,
    maxDepth,
    minDepth,
    parentId,
    oldPreviousSibling,
    newPreviousSibling,
  };
}
