import { forwardRef } from 'react';

import { ActionIcon, Group, Indicator, Paper, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { useNavigate } from '@tanstack/react-router';
import clsx from 'clsx';

import { useDeleteNotification } from '@/features/notifications/delete';
import { useMarkNotificationAsReadMutation } from '@/features/notifications/mark-as-read';

import { NotificationDto } from '@/shared/api';
import { formatRelativeTime } from '@/shared/lib';
import { Icon } from '@/shared/ui';

import classes from './NotificationCard.module.css';

export interface NotificationProps {
  id: string;
  title: string;
  body: string;
  date: Date;
  isRead: boolean;
  meta: NotificationDto['meta'];
  onClick?: () => void;
}

export const NotificationCard = forwardRef<HTMLDivElement, NotificationProps>((props, ref) => {
  const { id, title, body, date, isRead, meta, onClick } = props;

  const navigate = useNavigate();
  const clickHandlersMap: Partial<Record<NotificationDto['meta']['type'], () => void>> = {
    PAGE_PUBLISHED() {
      navigate({
        to: '/workspaces/$workspaceSlug/pages/$pageId',
        params: {
          workspaceSlug: meta.workspaceSlug,
          pageId: meta.pageId,
        },
      });
    },
  };

  const markAsReadMutation = useMarkNotificationAsReadMutation();
  const handleSpecificNotificationTypeClick = clickHandlersMap[meta.type];
  const handleClick = () => {
    handleSpecificNotificationTypeClick?.();
    onClick?.();
    markAsReadMutation.mutate(id);
  };

  const deleteMutation = useDeleteNotification();

  return (
    <Indicator disabled={isRead} color="red">
      <Paper ref={ref} p="sm" className={clsx(classes.root, handleSpecificNotificationTypeClick && classes.clickable)} onClick={handleClick}>
        <Group>
          <Text fw={500} flex={1} className={classes.title} c={isRead ? 'dimmed' : undefined}>
            {title}
          </Text>

          <ActionIcon
            size="sm"
            variant="subtle"
            color="gray"
            loading={deleteMutation.isPending}
            onClick={(e) => {
              e.stopPropagation();
              deleteMutation.mutate(id);
            }}
          >
            <Icon src={IconTrash} />
          </ActionIcon>
        </Group>

        <Text c="dimmed" size="sm" mb="sm">
          {formatRelativeTime(date)}
        </Text>

        <Text c="dimmed" size="sm">
          {body}
        </Text>
      </Paper>
    </Indicator>
  );
});
