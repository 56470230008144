import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usersQueryKeyFactory } from '@/entities/user';

import { api } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

export function useActivateUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => api.users.activateUser(id),
    async onSuccess({ id }) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.single(id) }),
      ]);

      notifySuccess('User has been activated');
    },
  });
}

export function useDeactivateUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => api.users.deactivateUser(id),
    async onSuccess({ id }) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.single(id) }),
      ]);

      notifySuccess('User has been deactivated');
    },
  });
}
