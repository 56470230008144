import { createFileRoute, Outlet } from '@tanstack/react-router';

import { AdminLink } from '@/widgets/admin-link';
import { AppLayout } from '@/widgets/layouts/app';

export const Route = createFileRoute('/_protected/account')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <AppLayout sidebar={<AdminLink />}>
      <Outlet />
    </AppLayout>
  );
}
