import { Tooltip, TooltipProps } from '@mantine/core';

export function DisallowDeleteTooptip(props: Omit<TooltipProps, 'label'>) {
  return (
    <Tooltip
      label="This page has child pages. Delete them first."
      {...props}
    />
  );
}
