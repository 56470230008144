import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { groupQueryKeyFactory } from '@/entities/group';

import { api } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';
import { CONFIRMATION_MODAL_KEY } from '@/shared/ui';

interface UseDeleteGroupOptions {
  onSuccess?: () => void;
}

export function useDeleteGroup({ onSuccess }: UseDeleteGroupOptions = {}) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (id: string) => api.groups.deleteGroup(id),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: groupQueryKeyFactory.list() });

      notifySuccess('Group has been deleted');
      onSuccess?.();
    },
  });

  return (id: string) => openContextModal({
    modal: CONFIRMATION_MODAL_KEY,
    title: 'Delete Group',
    size: 'sm',
    innerProps: {
      text: 'Are you sure you want to delete this group?',
      confirmButtonText: 'Delete',
      onConfirm: () => mutateAsync(id),
    },
  });
}
