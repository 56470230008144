'use client';

import { ActionIcon, Button } from '@mantine/core';
import { IconUserPlus } from '@tabler/icons-react';

import { AdminBreadcrumbs } from '@/widgets/admin-breadcrumbs';
import { PageHeader } from '@/widgets/layouts/app';

import { useNewUserModal } from '@/features/users/create';

import { Icon } from '@/shared/ui';

export function Toolbar() {
  const openModal = useNewUserModal();

  const breadcrumbs = <AdminBreadcrumbs links={[{ title: 'Users', href: '/admin/users', isActive: true }]} />;

  return (
    <PageHeader
      desktopLeftSection={breadcrumbs}
      mobileToolbar={breadcrumbs}
      mobileActions={(
        <ActionIcon size="lg" variant="transparent" onClick={openModal}>
          <Icon src={IconUserPlus} size={24} />
        </ActionIcon>
      )}
      desktopActions={(
        <Button size="xs" leftSection={<Icon src={IconUserPlus} />} onClick={openModal}>
          Add user
        </Button>
      )}
    />
  );
}
