export function downloadFile(name: string, file: Blob) {
  const url = URL.createObjectURL(file);
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 1000);

  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();
}
