'use client';

import { ActionIcon } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';

import { useEditUserModal } from '@/features/users/edit';

import { Icon } from '@/shared/ui';

export interface EditUserButtonProps {
  userId: string;
}

export function EditUserButton({ userId }: EditUserButtonProps) {
  const openModal = useEditUserModal();

  return (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => openModal(userId)}
    >
      <Icon src={IconPencil} size={20} />
    </ActionIcon>
  );
}
