import { ActionIcon, Anchor, Box, Breadcrumbs, ScrollArea, Text, Tooltip } from '@mantine/core';
import { IconHome2 } from '@tabler/icons-react';

import { Link } from '@tanstack/react-router';

import { Icon } from '../Icon';

export interface BreadcrumbLink {
  title: string;
  href: string;
  isActive: boolean;
}

export interface CommonBreadcrumbsProps {
  links: BreadcrumbLink[];
}

export function CommonBreadcrumbs({ links }: CommonBreadcrumbsProps) {
  const breadcrumbs = (
    <Breadcrumbs fz="sm">
      <Tooltip label="Home page">
        <ActionIcon size="sm" variant="subtle" mr={-4} renderRoot={p => <Link {...p} to="/" />}>
          <Icon src={IconHome2} />
        </ActionIcon>
      </Tooltip>

      {links.map(({ title, href, isActive }) => (
        <Tooltip key={`${title}-${href}`} label={title}>
          {isActive
            ? (
                <Text inherit c="dimmed" truncate>
                  {title}
                </Text>
              )
            : (
                <Anchor renderRoot={p => <Link {...p} to={href} />} inherit truncate>
                  {title}
                </Anchor>
              )}
        </Tooltip>
      ))}
    </Breadcrumbs>
  );

  return (
    <>
      <ScrollArea hiddenFrom="md" type="scroll" miw={0} maw="100%" offsetScrollbars="x" scrollbarSize={8} mb={-8}>
        {breadcrumbs}
      </ScrollArea>

      <Box miw={0} visibleFrom="md">
        {breadcrumbs}
      </Box>
    </>
  );
}
