import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { groupQueryKeyFactory } from '@/entities/group';
import { usersQueryKeyFactory } from '@/entities/user';

import { api } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';
import { CONFIRMATION_MODAL_KEY } from '@/shared/ui';

interface UseDeleteUserOptions {
  onSuccess?: () => void;
}

export function useDeleteUser({ onSuccess }: UseDeleteUserOptions = {}) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (id: string) => api.users.deleteUser(id),
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeyFactory.list() }),
      ]);

      notifySuccess('User has been deleted');
      onSuccess?.();
    },
  });

  return (id: string) => openContextModal({
    modal: CONFIRMATION_MODAL_KEY,
    title: 'Delete User',
    size: 'sm',
    innerProps: {
      text: 'Are you sure you want to delete this user?',
      confirmButtonText: 'Delete',
      onConfirm: () => mutateAsync(id),
    },
  });
}
