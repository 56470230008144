import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { cloneDeep } from 'lodash-es';

import { pagesQueryKeyFactory, useChildrenPagesQuery } from '@/entities/page';

import { api } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

type Page = NonNullable<ReturnType<typeof useChildrenPagesQuery>['data']>[number];

interface Payload {
  item: Page;
  fromParent: Page | null;
  toParent: Page | null;
  previousSiblingId: string | null;
}

export function useMovePageMutation() {
  const queryClient = useQueryClient();
  const { workspaceSlug } = useParams({ strict: false });

  const getCacheKey = (parentId: string | null) => pagesQueryKeyFactory.concreteChildren({
    workspaceSlug: workspaceSlug!,
    parentId,
  });

  return useMutation({
    mutationFn: ({ item, toParent, previousSiblingId }: Payload) => api.pages.movePage(item.id, {
      parentId: toParent && toParent.id,
      previousSiblingId,
    }),
    async onMutate({ fromParent, toParent, item, previousSiblingId }) {
      async function updateList(parentId: string | null, updater: (old: Page[]) => Page[]) {
        const queryKey = getCacheKey(parentId);

        await queryClient.cancelQueries({ queryKey });
        const previous = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (old?: Page[]) => old && updater(old));

        return { queryKey, previous };
      }

      async function updateParentChildrenCount(parentItem: Page | null, updater: (count: number) => number) {
        if (!parentItem)
          return null;

        const queryKey = getCacheKey(parentItem?.parentId ?? null);

        await queryClient.cancelQueries({ queryKey });
        const previous = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (old?: Page[]) => {
          if (!old)
            return old;

          const newData = [...old];
          const parentItemIndex = old.findIndex(({ id }) => id === parentItem?.id);
          if (parentItemIndex >= 0) {
            const newItem = cloneDeep(old[parentItemIndex]);
            newItem._count.children = updater(newItem._count.children);
            newData[parentItemIndex] = newItem;
          }

          return newData;
        });

        return { queryKey, previous };
      }

      return {
        // Optimistically update list item was moved from
        oldList: await updateList(fromParent?.id ?? null, old => (
          old.filter(page => page.id !== item.id)
        )),
        // Optimistically update list item was moved to
        newList: await updateList(toParent?.id ?? null, (old) => {
          const newParentList = old.filter(({ id }) => id !== item.id);

          const newIndex = previousSiblingId ? newParentList.findIndex(({ id }) => id === previousSiblingId) + 1 : 0;
          newParentList.splice(newIndex, 0, item);

          return newParentList;
        }),

        // Optimistically update _count.children of parent item it was moved from
        oldParentList: await updateParentChildrenCount(fromParent, c => c - 1),
        // Optimistically update _count.children of parent item it was moved to
        newParentList: await updateParentChildrenCount(toParent, c => c + 1),
      };
    },
    async onSuccess({ id, title }, { fromParent, toParent }) {
      notifySuccess(`Page "${title}" has been moved`);

      // Invalidate parent pages in order to retrieve their new children count
      // It's needed to disable "Delete" buttons for pages with children
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: pagesQueryKeyFactory.parents(id) }),
        fromParent && queryClient.invalidateQueries({
          queryKey: pagesQueryKeyFactory.singleById(fromParent.id),
        }),
        toParent && queryClient.invalidateQueries({
          queryKey: pagesQueryKeyFactory.singleById(toParent.id),
        }),
      ]);
    },
    async onError(_, __, ctx) {
      if (!ctx)
        return;

      queryClient.setQueryData(ctx.oldList.queryKey, ctx.oldList.previous);
      queryClient.setQueryData(ctx.newList.queryKey, ctx.newList.previous);

      if (ctx.oldParentList) {
        queryClient.setQueryData(ctx.oldParentList.queryKey, ctx.oldParentList.previous);
      }

      if (ctx.newParentList) {
        queryClient.setQueryData(ctx.newParentList.queryKey, ctx.newParentList.previous);
      }
    },
    async onSettled(_, __, ___, ctx) {
      if (!ctx)
        return;

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ctx.oldList.queryKey }),
        queryClient.invalidateQueries({ queryKey: ctx.newList.queryKey }),
        ctx.oldParentList && queryClient.invalidateQueries({ queryKey: ctx.oldParentList.queryKey }),
        ctx.newParentList && queryClient.invalidateQueries({ queryKey: ctx.newParentList.queryKey }),
      ]);
    },
  });
}
