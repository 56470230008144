import { Button, Card, Stack, TextInput, Title } from '@mantine/core';

import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { api } from '@/shared/api';
import { useAsyncForm } from '@/shared/hooks';

export function SetupForm() {
  const form = useAsyncForm({
    initialValues: {
      admin: {
        name: '',
        email: '',
      },
    },
  });

  const navigate = useNavigate();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => api.setup.completeSetup(form.values.admin),
    onSuccess() {
      navigate({ to: '/', replace: true });
    },
  });

  return (
    <Card w="100%" maw={{ base: 400 }} withBorder>
      <form onSubmit={form.onSubmit(() => mutateAsync())}>
        <Title order={3} mb="md">
          Setup
        </Title>

        <Stack>
          <TextInput
            label="Administrator name"
            {...form.getInputProps('admin.name')}
          />

          <TextInput
            label="Administrator email"
            description="This will be the first user's email. Use it to sign in later."
            {...form.getInputProps('admin.email')}
          />

          <Button type="submit" fullWidth loading={isPending}>
            Submit
          </Button>
        </Stack>
      </form>
    </Card>
  );
}
