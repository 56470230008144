import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notificationsQueryKeyFactory } from '@/entities/notifications';

import { api } from '@/shared/api';

export function useMarkNotificationAsReadMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => api.notifications.markAsRead(id),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: notificationsQueryKeyFactory.list() });
    },
  });
}

export function useMarkAllNotificationsAsReadMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => api.notifications.markAllAsRead(),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: notificationsQueryKeyFactory.list() });
    },
  });
}
