import { CommonBreadcrumbs, CommonBreadcrumbsProps } from '@/shared/ui';

const adminLink = {
  title: 'Administration',
  href: '/admin',
  isActive: false,
};

export function AdminBreadcrumbs({ links }: { links: CommonBreadcrumbsProps['links'] }) {
  return <CommonBreadcrumbs links={[adminLink, ...links]} />;
}
