import { useCallback } from 'react';

import { useLocation, useNavigate, useSearch } from '@tanstack/react-router';

export function useWritableSearchParams() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useSearch({ strict: false });

  const pathname = location.pathname;

  const createQueryString = useCallback((name: string, value: string | null) => {
    const params = new URLSearchParams(searchParams);
    if (value === null) {
      params.delete(name);
    } else {
      params.set(name, value);
    }

    return params.toString();
  }, [searchParams]);

  const writableSearchParams = new URLSearchParams(searchParams);

  writableSearchParams.set = (name: string, value: string) => {
    const query = createQueryString(name, value);
    navigate({ to: `${pathname}?${query}`, replace: true });
  };

  writableSearchParams.delete = (name: string) => {
    const query = createQueryString(name, null);
    navigate({ to: `${pathname}?${query}`, replace: true });
  };

  return writableSearchParams;
}
