export function getActiveElement(rects: DOMRect[]) {
  if (rects.length === 0) {
    return -1;
  }

  // TODO: header offset
  const closest = rects.reduce(
    (acc, item, index) => {
      if (Math.abs(acc.position) < Math.abs(item.y)) {
        return acc;
      }

      return {
        index,
        position: item.y,
      };
    },
    { index: 0, position: rects[0].y },
  );

  return closest.index;
}
