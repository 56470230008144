import { useMutation, useQueryClient } from '@tanstack/react-query';

import { userSettingsQuery } from '@/entities/user-settings';

import { api } from '@/shared/api';

export function useUpdateUserSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: api.userSettings.set,
    async onSuccess() {
      await queryClient.invalidateQueries(userSettingsQuery());
    },
  });
}
