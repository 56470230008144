import { createFileRoute, redirect } from '@tanstack/react-router';

import { SetupForm } from '@/views/setup';

import { BlankLayout } from '@/widgets/layouts/blank';

import { api } from '@/shared/api';

export const Route = createFileRoute('/setup')({
  async loader() {
    const response = await api.setup.isComplete();

    if (response.isComplete) {
      throw redirect({ to: '/', replace: true });
    }
  },
  component: () => (
    <BlankLayout>
      <SetupForm />
    </BlankLayout>
  ),
});
